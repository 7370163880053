var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"blue darken-3","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Interlingual Subtitle Voicing")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":"/notifications"}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-numeric-1-box-multiple-outline ")])],1)]}}])},[_c('span',[_vm._v("Notifications")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":"/videos"}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-video ")])],1)]}}])},[_c('span',[_vm._v("Video tutorials")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":"/faq"}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-comment-text-outline ")])],1)]}}])},[_c('span',[_vm._v("FAQ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":"/contact"}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-comment-question-outline ")])],1)]}}])},[_c('span',[_vm._v("Contact")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","to":"/"}},[_c('v-icon',_vm._g({},on),[_vm._v(" mdi-logout ")])],1)]}}])},[_c('span',[_vm._v("Logout")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }