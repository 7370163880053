<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >
          <strong>Please complete this form after you have read the <a href="/#/informationsheet"> Participant
            Information Sheet</a>.<br><br></strong>

          Thank you for considering taking part in this research. You should read the Participant
          Information Sheet before you agree to take part. If you have any questions, please use the <a
            href="/#/contact"
          >contact
            section</a> on
          the website (ISV code for anonymous communication, email for direct communication.) You will be able
          to download a PDF copy of this <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21159&authkey=AO5TvnSSutEOAEs"
            download
          >Consent
            Form</a> and the <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21160&authkey=AGk5_f0qdFMJhvQ"
            download
          >
            Participant Information Sheet</a> for your record, which you can refer to at any time.

          By <strong>ticking</strong> each box, you are consenting to the corresponding elements of the study. If
          you choose to leave any box un-ticked, this will mean that you <strong>DO NOT</strong> agree to that part
          of the study and this may mean you are ineligible for the study.<br><br>

          <v-simple-table class="grey lighten-5">
            <template v-slot:default>
            <thead>
              <tr>
                <th colspan="3">
                  Taking part in the study
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Statement</th>
                <th>Please tick each box</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>
                  I confirm that I have read and understood the Participant
                  Information Sheet for the above study in the previous section of
                  the ISV website. I have had the opportunity to consider the
                  information and ask questions through the <a
                    href="/#/contact"
                  >contact form</a>
                  on the website or via e-mail.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled1"
                  />
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>
                  I understand that my participation is voluntary and that I am free
                  to withdraw up to seven days after completing the post-
                  questionnaire without giving any reason by sending a request
                  with the ISV code stating that I wish to withdraw (website contact
                  section). In the event of withdrawal, the data collected will not be
                  used in the analysis.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled2"
                  />
                </td>
              </tr>
              <tr>
                <td>3.</td>
                <td>
                  I understand that the information I provide may be subject to
                  review by responsible individuals from the University of Surrey
                  and/or its regulators for monitoring and auditing purposes.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled3"
                  />
                </td>
              </tr>
              <tr>
                <td>4.</td>
                <td>I agree to take part in this study.</td>
                <td>
                  <v-checkbox
                    v-model="disabled4"
                  />
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td>
                  I understand that the information I provide will be used in various anonymised
                  outputs, including the resulting PhD thesis reports, publications, presentations,
                  websites etc.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled5"
                  />
                </td>
              </tr>
              <tr>
                <td>6.</td>
                <td>
                  I understand that any personal contact details that I provide
                  voluntarily at end of the research (website contact section), such
                  as my phone number and email address, will not be shared
                  beyond the research team.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled6"
                  />
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td>
                  I consent to the processing of my special category data (age) for
                  the purposes stated in the information sheet.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled7"
                  />
                </td>
              </tr>
              <tr>
                <td>8.</td>
                <td>
                  I consent to my audio recording/screen recording being used for
                  the purposes stated in the Participant Information Sheet.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled8"
                  />
                </td>
              </tr>
              <tr>
                <td>9.</td>
                <td>
                  I understand that the email address I use for direct contact in the
                  contact section of the ISV website will not be linked to my ISV
                  code nor any other data collected.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled9"
                  />
                </td>
              </tr>
            </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="grey lighten-5 ">
            <template v-slot:default>
            <thead>
              <tr>
                <th colspan="3">
                  Future use of the information gathered in the study
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th>Statement</th>
                <th>Please tick each box</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>10.</td>
                <td>
                  I agree not to share any tools or materials with anyone outside
                  of this research, nor publish them on any public forums or digital
                  platforms.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled10"
                  />
                </td>
              </tr>
              <tr>
                <td>11.</td>
                <td>
                  I consent to my identifiable personal data (if voluntarily left for
                  interview purposes or direct communication through the website
                  contact section) as well as anonymised data and screen
                  recordings to be deposited/stored in Surrey One Drive for similar
                  future ethically approved research studies and I understand that
                  I can withdraw this consent up to <strong>7 days</strong> after completing the
                  testing (i.e. submitting the post-testing questionnaire) without
                  providing any reason.
                </td>
                <td>
                  <v-checkbox
                    v-model="disabled11"
                  />
                </td>
              </tr>
            </tbody>
            </template>
          </v-simple-table>
          <br>
          This Electronic Consent Form replaces your signature. The date of the consent will automatically be
          logged after ticking this box.
          <v-checkbox
            v-model="buttonStatus"
            :disabled="!isDisabled"
            :label="`I agree and I want to continue`"
            @click="setCheckbox()"
          />
          <v-btn
            to="/pretestingquestionnaire"
            :disabled="!buttonStatus"
          >
            Go to questionnaire
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {store} from "../../store";
import ChoicesService from "../../services/ChoicesService";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {
    Header, Footer
  },
  props: {
    source: String,
  },
  data: () => ({
    buttonStatus: false,
    disabled1: false,
    disabled2: false,
    disabled3: false,
    disabled4: false,
    disabled5: false,
    disabled6: false,
    disabled7: false,
    disabled8: false,
    disabled9: false,
    disabled10: false,
    disabled11: false,
    disabled14: true,
    username: store.getters.getUsername,

  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 3).then(response => {
        this.$store.dispatch('setChoice', {'choice': 4}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 3) {
        this.disabled1 = true
              this.disabled2 = true
              this.disabled3 = true
              this.disabled4 = true
              this.disabled5 = true
              this.disabled6 = true
              this.disabled7 = true
              this.disabled8 = true
              this.disabled9 = true
              this.disabled10 = true
              this.disabled11 = true
              this.disabled14 = false
              this.buttonStatus = true
            }
    }
  },
  computed: {
    isDisabled() {
      return this.disabled1 === true && this.disabled2 === true && this.disabled3 === true && this.disabled4 === true
          && this.disabled5 === true && this.disabled6 === true && this.disabled7 === true && this.disabled8 === true
          && this.disabled9 === true && this.disabled10 === true && this.disabled11 === true && this.disabled14 === true
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
</style>