<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue darken-3"
    dark
  >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-toolbar-title
      style="width: 300px"
      class="ml-0 pl-4"
    >
      <span class="hidden-sm-and-down">Interlingual Subtitle Voicing</span>
    </v-toolbar-title>
    <v-spacer />
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/notifications"
        >
          <v-icon v-on="on">
            mdi-numeric-1-box-multiple-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Notifications</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/videos"
        >
          <v-icon v-on="on">
            mdi-video
          </v-icon>
        </v-btn>
      </template>
      <span>Video tutorials</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/faq"
        >
          <v-icon v-on="on">
            mdi-comment-text-outline
          </v-icon>
        </v-btn>
      </template>
      <span>FAQ</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/contact"
        >
          <v-icon v-on="on">
            mdi-comment-question-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Contact</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/"
        >
          <v-icon v-on="on">
            mdi-logout
          </v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
export default {
  name: "AdminHeader"
}
</script>

<style scoped>

</style>