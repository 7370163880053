<template>
  <div class="signup">
    <div class="login-form">
      <v-card
        title="Register"
        tag="article"
        style="max-width: 25rem;"
        class="mb-2"
      >
        <div>
          <b-alert
            :show="dismissCountDown"
            dismissible
            variant="danger"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            {{ alertMessage }}
          </b-alert>
        </div>
        <div>
          <b-alert
            variant="success"
            :show="successfullyRegistered"
          >
            <p>You have been successfully registered!</p>
            <p>Please click button to login.</p>
            <hr>
            <router-link to="/login">
              <v-btn
                  color="red"
                  class="center"
              >
                Login
              </v-btn>
            </router-link>
          </b-alert>
        </div>
        <div :hidden="!loginForm">
          <v-text-field
              solo
              label="Username/ISV Code"
            v-model="username"
              :rules="[() => !!validation || 'Your username must consist of 8 characters (4 capital\n'+
'            letters and 4 numbers). Your ISV Code is also your Username. In order to safeguard your\n'+
'            anonymity, we do not ask for your e-mail. This is why you should note down/make a screenshot of\n'+
'            your ISV Code and your Password since you will not able to retrieve it through your e-mail at a\n'+
'            later time.']"
            :state="validation"
            :maxlength="8"
          />

          <v-text-field
              solo
              label="Password"
              v-model="password"
          />

          <v-text-field
            solo
            label="Repeat password"
            v-model="confirmpassword"
            :rules=[rules.passwordMatch]
          />
          <v-checkbox
            v-model="checkbox"
            label="I have noted down my ISV Code and Password."
          >

          </v-checkbox>
          <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="onCapthcaVerified"
            @expired="onCaptchaExpired"
          />

          <v-btn
            @click="validateCaptcha(); loader = 'loading'"
            color="red"
            class="center"
            :loading="loading"
            :disabled="!buttonDisabled"
          >
            Register
          </v-btn>
        </div>

        <img
          class="text-center"
          src="../assets/University-of-Surrey-logo-(Black).png"
          height="95%"
          width="95%"
        >
      </v-card>
    </div>
  </div>
</template>

<script>
import AXIOS from './http-common'
import VueRecaptcha from 'vue-recaptcha'
import MailService from '../services/MailService'
import SpeakSubzService from "@/services/SpeakSubzService";

export default {
  name: 'SignUp',
  components: {VueRecaptcha},
  data() {
    return {
      loader: null,
      loading: false,
      loginForm: true,
      username: '',
      password: '',
      confirmpassword: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      alertMessage: '',
      successfullyRegistered: false,
      checkbox: false,
      sitekey: '6LdgTfUUAAAAAD4p4kyqyyuD_RJchIbbxexTKkEV',
      rules: {
      passwordMatch: () => (this.password === this.confirmpassword) || 'Password must match'
      }
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  },
  computed: {
    validation() {
      let counter = 0
      for (const c of this.username) {
        const alphaNumberic = 'abcdefghijklmnopqrstuvwxyz0123456789'.toUpperCase()
        if (alphaNumberic.includes(c)) {
          counter = counter + 1
        }
      }
      return counter === 8 && this.username.length === 8
    },
    buttonDisabled() {
      let counter = 0
      for (const c of this.username) {
        const alphaNumeric = 'abcdefghijklmnopqrstuvwxyz0123456789'.toUpperCase()
        if (alphaNumeric.includes(c)) {
          counter = counter + 1
        }
      }
      return (this.username.length === 8) && (counter === 8) && (this.password.length != 0) && (this.confirmpassword.length != 0) && (this.password === this.confirmpassword) && (this.checkbox === true)
    }

  },
  methods: {
    register: function () {
      if (this.$data.username === '' || this.$data.username == null) {
        this.$data.alertMessage = 'Please, fill "Username" field';
        this.showAlert();
      } else if (this.$data.password === '' || this.$data.password == null) {
        this.$data.alertMessage = 'Please, fill "Password" field';
        this.showAlert();
      } else if (this.$data.confirmpassword === '' || this.$data.confirmpassword == null) {
        this.$data.alertMessage = 'Please, confirm password';
        this.showAlert();
      } else if (this.$data.confirmpassword !== this.$data.password) {
        this.$data.alertMessage = 'Passwords are not match';
        this.showAlert();
      } else {

        var newUser = {
          'username': this.$data.username,
          'password': this.$data.password
        };

        AXIOS.post('/auth/signup', newUser)
            .then(response => {
              console.log(response);
              this.registerSpeakSubzUser(newUser.username, newUser.password);
              this.successAlert();
            }, error => {
              this.$data.alertMessage = (error.response.data.message.length < 150) ? error.response.data.message : 'Request error. Please, report this error website owners'
              this.showAlert();
            })
            .catch(error => {
              console.log(error);
              this.$data.alertMessage = 'Request error. Please, report this error website owners';
              this.showAlert();
            });
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    successAlert() {
      this.loginForm=false;
      const subject = "User " + this.username + " has registered"
      const message = "Hi, user with the ISV Code: " + this.username + " has registered to the site."
      MailService.sendMail("no-reply@isvresearch.eu", subject, message);
      this.username = '';
      this.password = '';
      this.confirmpassword = '';
      this.successfullyRegistered = true;
    },
    validateCaptcha() {
      this.$refs.recaptcha.execute()
    },
    onCapthcaVerified(recaptchaToken) {
      if (this.$data.username === '' || this.$data.username == null) {
        this.$data.alertMessage = 'Please, fill "Username" field';
        this.showAlert();
      } else if (this.$data.password === '' || this.$data.password == null) {
        this.$data.alertMessage = 'Please, fill "Password" field';
        this.showAlert();
      } else if (this.$data.confirmpassword === '' || this.$data.confirmpassword == null) {
        this.$data.alertMessage = 'Please, confirm password';
        this.showAlert();
      } else if (this.$data.confirmpassword !== this.$data.password) {
        this.$data.alertMessage = 'Passwords are not match';
        this.showAlert();
      } else {

        var newUser = {
          'username': this.$data.username,
          'password': this.$data.password,
          'recapctha_token': recaptchaToken
        };

        AXIOS.post('/auth/signup', newUser)
            .then(response => {
              console.log(response.status);
              this.registerSpeakSubzUser(newUser.username, newUser.password);
              this.successAlert();

            }, error => {
              this.$data.alertMessage = error.response.data.message;
              this.showAlert();
            })
            .catch(error => {
              console.log(error);
              this.$data.alertMessage = 'Request error. Please, report this error website owners';
              this.showAlert();
            });
      }
    },

    registerSpeakSubzUser(username, password) {
      SpeakSubzService.registerNewUser(username, password).then(response => {
        console.log(response);
      }, error => {
        console.log(error)
      })

    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style>
.signup{
  background-color: #eafafa;
}
.login-form {
  margin-left: 38%;
  margin-top: 200px;
}

.text-center {
  margin-left: 0%;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>