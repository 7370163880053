<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-center font-weight-light subtitle-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="270"
            width="395"
            class="center"
          >

          <h5>
            Thank you for participating in this research. For all further questions feel free to contact us through
            the <a href="/#/contact">contact section</a>, either using your ISV code for anonymous communication or your
            e-mail for direct
            communication and in case you want to participate in follow-up interviews and future research and
            development.<br>
            After some time, we will publish the results of this research, so feel free to check if you are interested.
          </h5>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
}
</script>

<style>
.center {
  width: 50%;
  height: 50%;
}
</style>