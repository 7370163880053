<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >
          <strong>Please <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21157&authkey=ACHlCL0lnbzQCdo"
            download
          > download</a> a
            copy of
            this information sheet for your records.<br><br></strong>

          We would like to invite you to participate in this research project which involves using a new
          technique: Interlingual Subtitle Voicing – ISV. This technique is based on speech recognition for
          the translation of subtitles. You should only participate if you want to; choosing not to take part will
          not disadvantage you in any way. Before you decide whether you would like to take part, it is
          important for you to understand why the research is being carried out and what your participation
          will involve. If you have any questions, you can contact us using the contact details at the end of
          this Participant information Sheet or the <a href="/#/contact"> contact section</a> on the website.<br>

          The research is supported by the Centre for Translation Studies at the University of Surrey in
          Guildford, UK. The purpose of this document is to inform you about all aspects of the research and
          the role you will play in it. You are invited as translation professionals with over 3 years of experience in any mode of translation
          (written, intepreting, subtitling etc.) to test out the workflow of the aforementioned new subtitling technique based on speech recognition, in order to
          provide feedback about the technique and the tools it uses. Your participation is completely
          voluntary and you can withdraw from the research at any point.<br><br>

          <h5>What is the purpose of the study?</h5>

          The aim and objective of this study is to explore a new technique for the translation of interlingual
          subtitles from English using Croatian as a representative of lesser-used languages. This will be
          carried out using online speech recognition services integrated within a specialised subtitling tool.<br>

          We are specifically interested in testing the workflow of the new technique, exploring different
          configurations with the end goal of providing subtitlers with an adaptable and customisable working
          method that caters for subtitlers’ mobility and mental well-being, as well as physical health through
          ergonomics, while decreasing stress and creating a pleasant working environment.<br>

          This experiment will involve completing an online training session for the suggested technique,
          translating various short videos using the specialised tool and providing feedback, both on the
          technique and the tool, in the form of online questionnaires.<br><br>

          <h5>Who is responsible for this study?</h5>

          This study is the responsibility of Mr. Željko Radić who holds an MA degree in Audiovisual
          Translation and is a current PhD student at the Centre for Translation Studies which is located at
          the University of Surrey. He is completing his research under the supervision of Dr Elena Davitti
          and Professor Sabine Braun, both from the University of Surrey. <br><br>

          <h5>Why have I been invited to take part?</h5>

          You have been invited to participate in this study because you are experienced
          translation professionals and you may be in position to use this technique in your future career.
          Your feedback will be invaluable for the
          refinement of the ISV technique and the development of the software.<br><br>

          <h5>Do I have to take part?</h5>

          Participation in this study is voluntary and you are not obliged to take part. The study will be
          described in this Participant Information Sheet and you will be given <strong>7 days</strong> to review this
          document, so you can decide whether you wish to take part in this study. Please <a href="/#/contact">contact
            us</a> if there
          is anything that is not clear, or if you have any questions, or if you would like more information.<br><br>

          <h5>What will happen to me if I decide to take part?</h5>

          If you decide to take part, you will be able to download this <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21157&authkey=ACHlCL0lnbzQCdo"
          >Participant Information Sheet</a>
          for your
          reference and will be asked to complete an online consent form to confirm you agree to
          your participation.
          If you decide to take part, you will be enrolled in a short online training course about the suggested
          technique, through which you will learn the required skills. You will then apply them by completing
          a series of exercises. Access to all relevant materials, tools and tutorials will be provided online, via
          a purpose-made website. You will be able to enrol and carry out the training in a completely
          anonymous manner, as your personal information will be replaced by alphanumeric codes (ISV
          codes) at the point of registration. These codes will also serve as your user names.<br><br>

          The course and related activities should take approximately 7 hours and you will have 30 days to
          complete them at your own pace. There will be a FAQ section for your reference, so your queries
          can be answered anonymously. However, in case you have any pressing questions, especially
          questions of technical nature, there will also be a <a href="/#/contact">support contact section</a> so you
          can interact with
          the research team directly, wherein your email address will not be linked to your ISV code or any
          other data.<br><br>
          You will need a computer (desktop, laptop) and an audio input device (headset with a microphone,
          standalone microphone or computer integrated microphone).<br><br>
          As part of the experiment, you will also be asked to fill in two questionnaires, one before the
          experiment and one after, each taking approx. 15 minutes to complete. Both questionnaires will be
          available online on the same platform that will be used to store the course materials.<br><br>

          <h5>What happens if I do not want to take part or if I change my mind?</h5>

          You are free to withdraw from the study whenever you want but we will only exclude your
          results from the analysis if you withdraw within the first 7 days after completing the post-
          testing questionnaire without providing a reason for this. You can do this by sending a request in
          the <a href="/#/contact">contact
            section</a>
          that you wish to withdraw using your ISV code.<br><br>

          <h5> What are the possible benefits in taking part?</h5>

          The training will provide you with an insight into the new technique and enable you to work on your
          voicing skills. You will also be able to use the specialised software throughout the duration of the
          research. The research will also introduce you to new technologies in the field of Audiovisual Translation
          that might increase your efficiency and give you an advantage on the AVT market.<br><br>

          <h5> Are there any potential risks involved?</h5>

          This is a low-risk experiment which you will take part in remotely. Information on the confidentiality
          of your data is available below.<br><br>

          <h5>How is the project being funded?</h5>

          This research is being funded by a grant under the Expanding Excellence in England (E3)
          programme, which was awarded to the Centre for Translation Studies at the University of Surrey in
          2019. For more information about this funding, please visit this page: <a
            href="https://www.surrey.ac.uk/news/surrey-wins-ps356m-expanding-excellence-england-award-intelligent-integration-human-and-machine"
            target="_blank"
          >link</a>
          <br><br>


          <h5> Will my participation be kept confidential?</h5>

          We are responsible for making sure your participation in this study is confidential. We must also
          ensure that any data collected is kept secure and is only used in the way described in this
          Participant Information Sheet. All the data will be protected using codes and at no point will you
          need to enter your names. Recordings of your performance will only capture what happens on
          screen and your voice, which can be distorted, if you so wish, in presentations to protect your
          anonymity.<br><br>
          You will not be able to be identified in any ensuing reports or publications.<br><br>
          Your anonymised information may be subject to review for monitoring and auditing purposes, by
          individuals from the University of Surrey and/or its regulators who will treat your data in
          confidence.<br><br>
          <h5>Will my data be shared or used in future research studies?</h5>

          We would like your permission to use anonymised data and the video recordings in future, similar
          research studies. You will be asked to confirm this in the Consent Form.<br><br>

          <h5> What will happen to the results of the study?</h5>

          We will produce a final report summarising the main findings of this research. This will be available
          for you to view or download from the website used for this research and a more extensive analysis
          will be available at a later date. We will publish the results from the research study in academic
          publications, present them at conferences, and report them in our PhD theses. Any published
          findings or quotations will maintain your confidentiality and anonymity. You will not be personally
          identified in any published material.<br><br>

          <h5> Who has reviewed this study?</h5>

          This research has been reviewed and received a favourable ethical opinion by the University of
          Surrey Ethics Committee.<br><br>

          <h5>What is personal data?</h5>

          ‘Personal Data’ means any information that identifies you as an individual. We will need to collect
          the relevant personal data, which will be used to complete the study. This section describes what
          that means.
          The information that we will collect will include your native and working languages and translation
          experience, which will be regarded as ‘personal data’ and age, which is regarded as a ‘special
          category personal data’. We will use this information as explained in the ‘What is the purpose of
          the study’ section above.<br><br>


          <h5> Who is handling my personal data?</h5>

          The University of Surrey, who has a legal responsibility for managing the personal data collected
          and will act as the ‘Data Controller’ for this study. The research team will process your personal
          data on behalf of the controller and they are responsible for looking after your information and
          using it correctly.<br><br>

          <h5> What will happen to my personal data?</h5>

          As a publicly-funded organisation, we have to ensure that when we use identifiable personal
          information from people who have agreed to take part in research, this data is processed fairly and
          lawfully. The University of Surrey processes personal data for the purposes of carrying out research in the
          public interest and special category data is processed on an additional condition
          necessary for research purposes. This means that when you agree to take part in this research
          study, we will use and look after your data in the ways needed to achieve the outcomes of the
          study.<br>

          Your personal data will be held and processed in the strictest confidence, and in accordance with
          current data protection regulations. When acting as the data controller, the University will keep any
          identifiable information you provide for 10 years after the study has finished, after which time any
          identifiers will be removed from the aggregated research data.<br>

          Your rights to access, change or move your information are limited, as we need to manage your
          information in specific ways in order for this research to be reliable and accurate. If you decide to
          withdraw from the study after the 7 day period, we may not be able to withdraw your data. We will
          keep and use the minimum amount of personally-identifiable information about you that we have
          already obtained in order to complete the study.<br>

          If you wish to make a complaint about how we have handled your personal data, you can contact
          our <a href="mailto:dataprotection@surrey.ac.uk">Data Protection Officer Suzie Mereweather</a> who will
          investigate the matter. If you are not satisfied with our response or believe we are
          processing your personal data in a way that is not lawful, you can make a complaint to the <a
            href="https://ico.org.uk/"
            target="_blank"
          > Information
            Commissioner’s Office (ICO)</a>.<br>

          You can find out more about how we use your information by visiting <a
            href="https://www.surrey.ac.uk/information-management/data-protection"
            target="_blank"
          >this link</a>
          and/or by contacting <a
            href="mailto:dataprotection@surrey.ac.uk"
          >dataprotection@surrey.ac.uk</a>.<br><br>


          <h5> What if you have a query or something goes wrong?</h5>

          If you are unsure about something you can contact the research team for further advice using the
          contact details at the bottom of this Participant Information Sheet.<br>

          However, if your query has not been handled to your satisfaction, or if you are unhappy and wish to
          make a formal complaint to someone independent of the research team, then please contact:<br><br>

          <strong> Research Integrity and Governance Office (RIGO)<br>
            Research and Innovation Services<br>
            University of Surrey<br>
            Senate House, Guildford, Surrey, GU2 7XH<br>
            Phone: +44 (0)1483 689110<br>
            Email: <a href="mailto:rigo@surrey.ac.uk">rigo@surrey.ac.uk</a><br><br></strong>

          The University has the relevant insurance policies for this study in place. If you wish to make a
          complaint or have any concerns about any aspect of the way you have been treated during the
          course of this study, then you should follow the instructions provided above.<br><br>


          <h5> Who should I contact for further information?</h5>

          If you have any questions or require more information about this study, please contact the research
          team using the following contact details: <a
            href="mailto:z.radic@surrey.ac.uk"
          >z.radic@surrey.ac.uk</a> or
          submit your query on the <a href="/#/contact">contact section</a> of the ISV website<br><br>


          Thank you for reading this Participant Information Sheet and for considering taking part in this
          research.<br><br>

          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have read the sheet`"
            @click="setCheckbox()"
          />

          <v-btn
            :disabled="!buttonStatus"
            to="/consentform"
          >
            Go to consent form
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 2).then(response => {
        this.$store.dispatch('setChoice', {'choice': 3}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 2) {
        this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }

}
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>