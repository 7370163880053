<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5 "
      >
        <img
          src="../../assets/University-of-Surrey-logo-(Black).png"
          height="280"
          width="395"
          class="center"
        >

        <v-layout
          align-center
          justify-center
        >
          <v-flex xs6>
            <div class="text-xs-center">
              <v-simple-table class="grey lighten-5">
                <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date
                    </th>
                    <th class="text-left">
                      Text
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in notifications.slice().reverse()"
                    :key="item.name"
                  >
                    <td>{{ item.date.toString().replace("T", " ") }}</td>
                    <td>{{ item.notification }}</td>
                  </tr>
                </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NotificationsDataService from "../../services/NotificationsDataService";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {store} from "../../store";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    disabled: false,
    notifications: [],
    date: "",
    notification: "",

  }),
  methods: {
    getAllNotifications() {
      NotificationsDataService.getForUser(store.getters.getUsername).then(response => {
        this.notifications = response.data;
      })
          .catch(e => {
            console.log(e);
          })
    }
  },
  mounted() {
    this.getAllNotifications()
  }
}

</script>

<style>
</style>