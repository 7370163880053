<template>
  <div div="signin" class="form_center">
    <div class="login-form">
      <v-card
          title="Login"
          tag="article"
          style="width: 25rem;"
          class="mb-2"
      >
        <div>
          <b-alert
              :show="dismissCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            {{ alertMessage }}
          </b-alert>
        </div>
        <div>
          <v-form>
            <v-text-field
                label="Username/ISV Code"
                v-model="username"
                solo
                :maxlength="8"
            />

            <v-text-field
                type="password"
                label="Password"
                v-model="password"
                solo
                :maxlength="30"
            />
          </v-form>

          <v-btn
              @click="validateCaptcha(); loader = 'loading'"
              color="red"
              class="center"
              :loading="loading"
          >
            Login
          </v-btn>
        </div>

        <img
            class="text-center"
            src="../assets/University-of-Surrey-logo-(Black).png"
            height="95%"
            width="95%"
        >

        <vue-recaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="onCapthcaVerified"
            @expired="onCaptchaExpired"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import AXIOS from './http-common'
import VueRecaptcha from 'vue-recaptcha'
import ChoicesService from "@/services/ChoicesService";

export default {
  name: 'SignIn',
  components: {VueRecaptcha},
  data() {
    return {
      loader: null,
      loading: false,
      username: '',
      password: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      alertMessage: 'Request error',
      sitekey: '6LdgTfUUAAAAAD4p4kyqyyuD_RJchIbbxexTKkEV'
    }
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert(msg) {
      this.alertMessage = msg
      this.dismissCountDown = this.dismissSecs
    },
    validateCaptcha() {
      this.$refs.recaptcha.execute()
    },
    getChoices(username) {
      ChoicesService.getAll(username).then(response => {
        this.$store.dispatch('setChoice', response.data)
      })
    },
    onCapthcaVerified(recaptchaToken) {
      AXIOS.post(`/auth/signin`, {
        'username': this.$data.username,
        'password': this.$data.password,
        'recapctha_token': recaptchaToken
      })
          .then(response => {
            this.$store.dispatch('login', {
              'roles': response.data.authorities,
              'username': response.data.username
            });
            this.$store.dispatch('getChoice', {
              'username': this.$data.username
            });
            this.$store.dispatch('getLastLogin', {
              'username': this.$data.username
            });
            this.$router.push('/introduction')
          }, error => {
            this.showAlert(error.response.data.message);
          })
          .catch(e => {
            console.log(e);
            this.showAlert('Server error. Please, report this error to website owner');
          })
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style>
.form_center {
  background-color: #eafafa;
  padding: 50px 0;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>