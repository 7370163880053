<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >

          A) Navigating SpeakSubz<br>
          <video
            id="video1"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/Navigating.mp4"
              type="video/mp4"
            >
          </video>

          B) The ISV Workflow<br>
          <video
            id="video2"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/Workflow.mp4"
              type="video/mp4"
            >
          </video>

          C) Correcting Speech Recognition Errors<br>
          <video
            id="video3"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/Correcting.mp4"
              type="video/mp4"
            >
          </video>

          D) Saving Subtitles and Screen Recordings<br>
          <video
            id="video4"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/Saving.mp4"
              type="video/mp4"
            >
          </video>

          <br>
          *If the system does not record your sound, choose the second option (window/prozor), instead of "entire screen/cijeli zaslon".
          You can check this under "Recordings" button in each task. Feel free to try this in Exercise 0, 2 or 6 where you can practise recording.
          <v-img src="../../assets/faq/9.jpg"></v-img><br>

          <br>

          **To stop screen/voice recording, press STOP (red button) and then enter the name of the file, either Unedited or Final,
          depending on which process was recorded. You can check the recording by clicking on the Recordings button in each task.<br>
          <v-img src="../../assets/faq/11_1.jpg"></v-img><br>
          <v-img src="../../assets/faq/11_1.jpg"></v-img><br>

          ***
          Once you complete recording your screen, especially for exercises 7 and 8, please check whether both processes (translation/unedited and editing/final) are recorded. You can check this in exercises 0, 2 or 6 by clicking on "RECORDINGS" in each task. To record your screen, press the green RECORD button. When done, press the red STOP button and enter the name of the recording (either unedited or final).

          The same applies for your subtitles, you should see both the edited and the unedited version and you can check this by clicking on "SNAPSHOTS" in each task. You can save subtitles using the blue SAVE AS button.
          <v-img src="../../assets/faq/12_1.jpg"></v-img><br>
          <v-img src="../../assets/faq/12_2.jpg"></v-img><br>

          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have watched all the tutorials`"
            @click="setCheckbox()"
          />

          <v-btn
            to="/speaksubz"
            :disabled="!buttonStatus"
          >
            Go to SpeakSubz
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "ISVTutorials",
  props: {
    source: String,
  },

  components: {
    Header,
    Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
    buttonDisabled: false,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 8).then(response =>{
          this.$store.dispatch('setChoice', {'choice': 9});
      }
    );
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 8) {
        this.checkboxStatus = false
              this.buttonStatus = true
            }
    },
  },
  mounted() {
    this.checkStatus()
  }

}
</script>

<style>
video {
  width: 100%;
  height: auto;
  max-height: 100%;}
</style>