<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="270"
            width="395"
            class="center"
          >

          Dear participants, thank  you for taking part in the ISV research.<br><br>

          If you would like to give us some additional feedback on the ISV technique and SpeakSubz and are interested in
          contributing to further development in this field, there is an option of leaving your e-mail or phone number here
          so we can contact you within 7 days for a follow-up online or phone interview (no longer than 40 minutes).
          We will ask you a few additional questions that will give you an opportunity to elaborate on your experience with the technique,
          software, subtitler experience (SUBX), this website and to explore your view on future practices.<br><br>

          After leaving your e-mail contact here, you will be sent an interview related Participant Information Sheet and an
          Informed Consent Form (both of which you can also download here: <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21118&authkey=AFa43Mgd2WX3znA"
            download
          >Information Sheet</a>
          and <a
            href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21117&authkey=ABbuKsyMX-HYbrI"
            download
          >Consent Form</a>) to read, date and sign after which a meeting
          will be arranged over your preferred platform (Google Hangout/Skype/Zoom etc.) or over the phone.
          For the purposes of this interview only audio is necessary, so the camera of the device you will be using for the interview can be turned off.<br><br>

          Your personal data, e-mail and/or phone number will be stored in accordance with the Participant Information Sheet and the Informed Consent Form
          and will not be paired with your ISV code so that your results cannot be linked with the data you leave here.<br><br>

          If you have any queries, feel free to contact the head researcher at <a href="mailto:z.radic@surrey.ac.uk">z.radic@surrey.ac.uk</a>
          or use the <a href="/#/contact">contact section</a> of this website.<br><br>


          <v-checkbox
            v-model="followupButtonStatus"
            :label="`I would like to participate in follow up interview`"
          />
          <div :hidden="!followupButtonStatus">
            <strong>Please <a
              href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21118&authkey=AFa43Mgd2WX3znA"
              download
            > download</a> a
              copy of
              this information sheet for your records.<br><br></strong>

            We would like to invite you to participate in optional follow up interviews after testing out the new technique:
            Interlingual Subtitle Voicing – ISV. This technique is based on speech recognition for the translation of subtitles.
            You should only participate if you want to; choosing not to take part will not disadvantage you in any way.
            Before you decide whether you would like to take part, it is important for you to understand why the research is being
            carried out and what your participation will involve.
            If you have any questions, you can contact us using the contact details at the end of this
            Participant information Sheet or the <a href="/#/contact"> contact section</a> on the website.<br>

            The research is supported by the Centre for Translation Studies at the University of Surrey in Guildford, UK.
            The purpose of this document is to inform you about all aspects of the research and the role you will play in it.
            You are invited as language and translation professionals to provide additional feedback about the ISV technique and the specialised
            subtitling software based on speech recognition, in the form of an online (Google Hangout, Zoom, Skype etc.) or phone interview.
            Your participation is completely voluntary and you can withdraw from the research at any point but need to do this 7 days
            after the interview if you do not want your answers to be included in the data analysis.<br><br>

            <h5>What is the purpose of the study?</h5>

            The aim and objective of this study is to explore a new technique for the translation of interlingual subtitles from English
            using Croatian as a representative of lesser-used languages. This will be carried out using online speech recognition services integrated
            within a specialised subtitling tool.<br>

            We are specifically interested in testing the workflow of the new technique, exploring different configurations with the end goal of providing
            subtitlers with an adaptable and customisable working method that caters for subtitlers’ mobility and mental well-being,
            as well as physical health through ergonomics, while decreasing stress and creating a pleasant working environment.<br>
            <br><br>

            <h5>Who is responsible for this study?</h5>

            This study is the responsibility of Mr. Željko Radić who holds an MA degree in Audiovisual Translation and is a
            current PhD student at the Centre for Translation Studies which is located at the University of Surrey. He is completing
            his research under the supervision of Dr Elena Davitti and Professor Sabine Braun, both from the University of Surrey.<br><br>

            <h5>Why have I been invited to take part?</h5>

            You are invited to participate in this follow-up interview because you are a language and translation professional
            who works from English into Croatian and you have completed the ISV technique training on the ISV research website,
            using specialised software based on speech recognition, as well as questionnaires. Your feedback will be invaluable for
            the refinement of the ISV technique and the development of the software.<br><br>

            <h5>Do I have to take part?</h5>

            Participation in this interview is voluntary and you are not obliged to take part.
            The interview will be described in this Participant Information Sheet and you will be given <strong>7 days</strong> to review this
            document, so you can decide whether you wish to take part in this study. Please <a href="/#/contact">contact
              us</a> if there
            is anything that is not clear, or if you have any questions, or if you would like more information.<br><br>

            <h5>What will happen to me if I decide to take part?</h5>

            If you decide to take part, you will be able to download this <a
              href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21118&authkey=AFa43Mgd2WX3znA"
              download
            >Participant Information Sheet</a>
            for your
            reference and will be asked to complete an online Informed Consent Form
            to confirm you agree to your participation. You can also download the Informed Consent Form,
            fill it in, print, scan and send back to the researcher.<br><br>

            This interview will involve answering questions about the suggested ISV technique that you have had an opportunity
            to try out and evaluate in the main part of the experiment. The goal of the interview is gathering additional feedback
            and applying possible changes in subsequent iterations of the technique and/or software, learning about workflow variations
            with the emphasis on “working away from the desk” environment and getting feedback on the ISV website itself that might also be
            applied in future versions of the website.<br><br>

            If you decide to take part, you will be interviewed online using your preferred platform (e.g. Google Hangout, Skype, Zoom etc.)
            or by phone if that is more convenient for you and if you left your phone number in the relevant section of ISV website.
            The interview should not exceed 40 minutes. The date of the interview will be agreed upon beforehand. An online invitation
            with the date and time will be sent to the e-mail left in the Optional Follow-up Interview section of the ISV website or be arranged by phone.<br><br>

            You can turn off your camera during the interview (optional)
            but the audio has to be enabled for the interview to be able to take place.<br><br>

            In case of technical difficulties or any other problems, the interview can be
            postponed to a more suitable time by sending an e-mail to the researcher, preferably 2 hours before the interview if possible.<br><br>

            <h5>What happens if I do not want to take part or if I change my mind?</h5>

            You are free to withdraw from the study whenever you want but we will only exclude your interview responses from the
            analysis if you withdraw within the first <strong>7 days</strong> after completing the interview. You can do this by sending a request in
            the <a href="/#/contact">contact
              section</a>
            of the website.<br><br>

            <h5> What are the possible benefits in taking part?</h5>

            Your participation in this interview will enable you to share and elaborate upon your views on an emerging technique
            and on the future work in the industry. You will contribute to the research and possibly directly influence the
            development of the ISV technique and of the specialised software that might become a part of the usual subtitling workflow in the future.
            You will also be able to share your thoughts about introducing variety in the subtitling routine that also strives to preserve subtitlers’
            physical and mental health.<br><br>

            <h5> Are there any potential risks involved?</h5>

            This is a low-risk interview in which you will take part remotely from your home to ensure your safety,
            especially in the current Covid-19 affected situation.
            Information on the confidentiality of your data is available below.<br><br>

            <h5>How is the project being funded?</h5>

            This research is being funded by a grant under the Expanding Excellence in England (E3)
            programme, which was awarded to the Centre for Translation Studies at the University of Surrey in
            2019. For more information about this funding, please visit this page: <a
              href="https://www.surrey.ac.uk/news/surrey-wins-ps356m-expanding-excellence-england-award-intelligent-integration-human-and-machine"
              target="_blank"
            >link</a>
            <br><br>


            <h5> Will my participation be kept confidential?</h5>

            We are responsible for making sure your participation in this study is confidential.
            We must also ensure that any data collected is kept secure and is only used in the way described in this Participant Information Sheet.
            All the data will be protected using codes. Recordings of your interview and subsequent transcripts will be anonymised, i. e. all
            identifiable data will be coded and stored on the Surrey One Drive and an external back-up drive protected by a password.<br><br>

            Recordings of the interview and screenshots will not be used in any ensuing reports, presentations or publications.
            They will be transcribed, analysed and certain parts might be quoted in the data analysis without disclosing your personal data.
            Transcripts will also be de-identified and stored on the Surrey One Drive and a password protected back-up external drive.<br><br>

            Your anonymised information may be subject to review for monitoring and auditing purposes, by individuals
            from the University of Surrey and/or its regulators who will treat your data in confidence.<br><br>

            <h5>Will my data be shared or used in future research studies?</h5>

            We would like your permission to the anonymised transcripts of the interview in the future, similar research studies. You will be asked to confirm this in the Consent Form.<br><br>

            <h5> What will happen to the results of the study?</h5>

            We will produce a final report summarising the main findings of this research. This will be available for you to
            download from the website used for this research and a more extensive analysis will be available at a later
            date. We will publish the results from the research study in academic publications, present them at conferences, and report
            them in our PhD theses. Any published findings or quotations will maintain your confidentiality and anonymity.
            You will not be personally identified in any published material.<br><br>

            <h5> Who has reviewed this study?</h5>

            This research has been reviewed and received a favourable ethical opinion by the University of
            Surrey Ethics Committee.<br><br>

            <h5>What is personal data?</h5>

            ‘Personal Data’ means any information that identifies you as an individual. We will need to collect
            the relevant personal data, which will be used to complete the study. This section describes what
            that means.
            The information that we will collect will include your e-mail that will not be paired with your ISV code,
            as well as recordings of the interview. We will use this information as explained in the ‘What is the purpose of the study’ section above.br><br>


            <h5> Who will be handling my personal data?</h5>

            The University of Surrey, who has a legal responsibility for managing the personal data collected
            and will act as the ‘Data Controller’ for this study. The research team will process your personal
            data on behalf of the controller and they are responsible for looking after your information and
            using it correctly.<br><br>

            <h5> What will happen to my personal data?</h5>

            As a publicly-funded organisation, we have to ensure that when we use identifiable personal information from people
            who have agreed to take part in research, this data is processed fairly and lawfully. The University of Surrey
            processes personal data for the purposes of carrying out research in the public interest and special category data
            is processed on an additional condition necessary for research purposes. This means that when you agree to take part
            in this research study, we will use and look after your data in the ways needed to achieve the outcomes of the study. <br>

            Your personal data will be held and processed in the strictest confidence, and in accordance with current data protection regulations.
            When acting as the data controller, the University will keep any identifiable information you provide for 10 years
            after the study has finished, after which time any identifiers will be removed from the aggregated research data. br>

            Your rights to access, change or move your information are limited, as we need to manage your information in specific ways in
            order for this research to be reliable and accurate. If you decide to withdraw from the study after the 7 day period, we
            may not be able to withdraw your data. We will keep and use the minimum amount of personally-identifiable information about
            you that we have already obtained in order to complete the study.<br>

            If you wish to make a complaint about how we have handled your personal data, you can contact
            our <a href="mailto:dataprotection@surrey.ac.uk">Data Protection Officer Suzie Mereweather</a> who will
            investigate the matter. If you are not satisfied with our response or believe we are
            processing your personal data in a way that is not lawful, you can make a complaint to the <a
              href="https://ico.org.uk/"
              target="_blank"
            > Information
              Commissioner’s Office (ICO)</a>.<br>

            You can find out more about how we use your information by visiting <a
              href="https://www.surrey.ac.uk/information-management/data-protection"
              target="_blank"
            >this link</a>
            and/or by contacting <a
              href="mailto:dataprotection@surrey.ac.uk"
            >dataprotection@surrey.ac.uk</a>.<br><br>


            <h5> What if you have a query or something goes wrong?</h5>

            If you are unsure about something you can contact the research team for further advice using the
            contact details at the bottom of this Participant Information Sheet.<br>

            However, if your query has not been handled to your satisfaction, or if you are unhappy and wish to
            make a formal complaint to someone independent of the research team, then please contact:<br><br>

            <strong> Research Integrity and Governance Office (RIGO)<br>
              Research and Innovation Services<br>
              University of Surrey<br>
              Senate House, Guildford, Surrey, GU2 7XH<br>
              Phone: +44 (0)1483 689110<br>
              Email: <a href="mailto:rigo@surrey.ac.uk">rigo@surrey.ac.uk</a><br><br></strong>

            The University has the relevant insurance policies for this study in place. If you wish to make a
            complaint or have any concerns about any aspect of the way you have been treated during the
            course of this study, then you should follow the instructions provided above.<br><br>


            <h5> Who should I contact for further information?</h5>

            If you have any questions or require more information about this study, please contact the research
            team using the following contact details: <a
              href="mailto:z.radic@surrey.ac.uk"
            >z.radic@surrey.ac.uk</a> or
            submit your query on the <a href="/#/contact">contact section</a> of the ISV website<br><br>


            Thank you for reading this Participant Information Sheet and for considering taking part in this
            research.<br><br>

            Thank you for considering taking part in this research. You should read the Participant
            Information Sheet before you agree to take part. If you have any questions, please use the <a
              href="/#/contact"
            >contact
              section</a> on
            the website  (e-mail or phone number). You will be able
            to download a PDF copy of this <a
              href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21117&authkey=ABbuKsyMX-HYbrI"
              download
            >Consent
              Form</a> and the <a
              href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21118&authkey=AFa43Mgd2WX3znA"
              download
            >
              Participant Information Sheet</a> for your record, which you can refer to at any time.

            By <strong>ticking</strong> each box, you are consenting to the corresponding elements of the study. If
            you choose to leave any box un-ticked, this will mean that you <strong>DO NOT</strong> agree to that part
            of the study and this may mean you are ineligible for the study.<br><br>

            <v-simple-table class="grey lighten-5">
              <template v-slot:default>
              <thead>
                <tr>
                  <th colspan="3">
                    Taking part in the study
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Statement</th>
                  <th>Please tick each box</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>
                    I confirm that I have read and understood the Participant
                    Information Sheet for the above study in the previous section of
                    the ISV website. I have had the opportunity to consider the
                    information and ask questions through the <a
                      href="/#/contact"
                    >contact form</a>
                    on the website or via e-mail.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled1"
                    />
                  </td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>
                    I understand that my participation is voluntary and that I am free to withdraw up to seven days after the
                    interview without giving any reason by sending an e-mail I wish to withdraw (website contact section).
                    In the event of withdrawal within the 7 days, the data collected will not be used in the analysis.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled2"
                    />
                  </td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>
                    I understand that the information I provide may be subject to
                    review by responsible individuals from the University of Surrey
                    and/or its regulators for monitoring and auditing purposes.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled3"
                    />
                  </td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>I agree to take part in this study.</td>
                  <td>
                    <v-checkbox
                      v-model="disabled4"
                    />
                  </td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>
                    I understand that the information I provide will be used in various anonymised
                    outputs, including the resulting PhD thesis reports, publications, presentations,
                    websites etc.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled5"
                    />
                  </td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>
                    I understand that any personal contact details that I provide
                    voluntarily at end of the research (website contact section), such
                    as my phone number and email address, will not be shared
                    beyond the research team.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled6"
                    />
                  </td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>
                    I consent to my interview recordings and  transcripts being used for the purposes stated in the Participant Information Sheet.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled7"
                    />
                  </td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>
                    I understand that the email address I use for direct contact in the contact section
                    of the ISV website will not be linked to my ISV code nor any other data collected.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled8"
                    />
                  </td>
                </tr>
              </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="grey lighten-5">
              <template v-slot:default>
              <thead>
                <tr>
                  <th colspan="3">
                    Future use of the information gathered in the study
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Statement</th>
                  <th>Please tick each box</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>9.</td>
                  <td>
                    I consent to my identifiable personal data (if voluntarily left for optional follow-up interview purposes)
                    and interview recordings as well as subsequent transcripts to be deposited/stored in Surrey One Drive
                    for similar future ethically approved research studies and I understand that I can withdraw this consent up to
                    7 days after the interview without providing any reason if I do not want my answers to be used.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled9"
                    />
                  </td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>
                    I agree for my personal contact details to be stored by the research team who may wish to invite
                    me to participate in follow-up studies that relate to this project or in future studies being conducted at the University of Surrey.
                  </td>
                  <td>
                    <v-checkbox
                      v-model="disabled10"
                    />
                  </td>
                </tr>
              </tbody>
              </template>
            </v-simple-table>
            This Electronic Consent Form replaces your signature. The date of the consent will automatically be
            logged after ticking this box.<br><br>
            You can also <a
              href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21117&authkey=ABbuKsyMX-HYbrI"
              download
            >download</a>,
            fill in, date and sign the form, then scan it and send it to <a href="mailto:z.radic@surrey.ac.uk">z.radic@surrey.ac.uk</a>.<br><br>

            <v-text-field
              v-model="name"
              filled
              label="Your Name"
            />
            <v-text-field
              v-model="email"
              filled
              label="Your e-mail"
            />
            <v-text-field
              v-model="phone"
              filled
              label="Your phone number (optional)"
            />
            <v-text-field
              v-model="platform"
              filled
              label="Your preferred platform (Skype/Zoom/Google Hangouts/Other)"
            />

            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="sendEmail"
                class="white--text"
                color="primary"
                depressed
                :disabled="!isDisabled"
              >
                Submit
              </v-btn>
              <v-snackbar
                v-model="snackbar"
              >
                Message was sent. Thank you!
                <v-btn
                  color="pink"
                  text
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </v-snackbar>
            </v-card-actions>
          </div>

          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I would like to continue to results`"
            @click="setCheckbox()"
          />

          <v-btn
            to="/results"
            :disabled="!buttonStatus"
          >
            Go to results
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Axios from "@/components/http-common";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
    followupButtonStatus: false,
    checkboxOptionalStatus: true,
    snackbar: false,
    text: 'Message was sent',
    followupStatus: false,
    disabled1: false,
    disabled2: false,
    disabled3: false,
    disabled4: false,
    disabled5: false,
    disabled6: false,
    disabled7: false,
    disabled8: false,
    disabled9: false,
    disabled10: false,
    email: null,
    phone: null,
    platform: null,
    name: null
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 11).then(response => {
        this.$store.dispatch('setChoice', {'choice': 12}) });

      ChoicesService.setChoice(store.getters.getUsername, 12).then(response => {
      this.$store.dispatch('setChoice', {'choice': 12}) });
  },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 11) {

              this.checkboxStatus = false
              this.buttonStatus = true
            }
    },
    sendEmail: function () {
      this.$data.snackbar = true;
      const bodyData = new FormData();
        bodyData.set("email", "no-reply@isvresearch.eu")
        bodyData.set("subject", "ISVResearch: Follow up interview contact ")
        bodyData.set("message", "Name: " + this.name + "\n Email: " + this.email + "\n Phone number: " + this.phone + "\n Platform: " + this.platform)
      Axios.post("/sendSimpleEmail", bodyData).then(response => {
        if (response !== false) {
          console.log("");
        }
      }, error => {
        console.log(error)
      })
          .catch(error => {
            console.log(error);
          });

    },
  },
  computed: {
    isDisabled() {
      return this.disabled1 === true && this.disabled2 === true && this.disabled3 === true && this.disabled4 === true
          && this.disabled5 === true && this.disabled6 === true && this.disabled7 === true && this.disabled8 === true
          && this.disabled9 === true && this.disabled10 === true && this.name.length > 0 && this.email.length > 0 && this.platform.length > 0
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>
