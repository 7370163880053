import Vue from 'vue';
import Vuex from 'vuex';
import ChoicesService from "@/services/ChoicesService";
import UserService from "@/services/UserService";

Vue.use(Vuex);

const state = {
    role: sessionStorage.getItem('user-role') || '',
    username: sessionStorage.getItem('user-name') || '',
    authorities: sessionStorage.getItem('authorities') || '',
    timeDisplayed: sessionStorage.getItem('time-displayed') || '',
    lastLogin: sessionStorage.getItem('last-login') || '',
    choices: sessionStorage.getItem('choices') || '',
};

const getters = {
    isAuthenticated: state => {
        return state.role != null && state.role !== '';
    },
    isAdmin: state => {
        return state.role === 'admin';
    },
    getUsername: state => {
        return state.username;
    },
    getAuthorities: state => {
        return state.authorities;
    },
    getTimeDisplayed: state => {
        return state.timeDisplayed;
    },
    getChoices: state => {
        return state.choices;
    },
    getLastLogin: state => {
        return state.lastLogin;
    }
};

const mutations = {
    auth_login: (state, user) => {
        sessionStorage.setItem('user-name', user.username);
        sessionStorage.setItem('user-authorities', user.roles);

        state.username = user.username;
        state.authorities = user.roles;
        let isUser = false;
        let isAdmin = false;
        for (let i = 0; i < user.roles.length; i++) {
            if (user.roles[i].authority === 'ROLE_USER') {
                isUser = true;
            } else if (user.roles[i].authority === 'ROLE_ADMIN') {
                isAdmin = true;
            }
        }
        if (isUser) {
            sessionStorage.setItem('user-role', 'user');
            state.role = 'user';
        }
        if (isAdmin) {
            sessionStorage.setItem('user-role', 'admin');
            state.role = 'admin';
        }
    },
    auth_logout: () => {
        state.token = '';
        state.role = '';
        state.username = '';
        state.authorities = [];
        sessionStorage.removeItem('user-role');
        sessionStorage.removeItem('user-name');
        sessionStorage.removeItem('user-authorities');
        sessionStorage.removeItem('time-displayed');
    },
    set_choice: (state, choice) =>{
        state.choices = choice;
        sessionStorage.setItem('choices', choice);
    },
    set_lastlogin: (state, choice) =>{
        state.lastLogin = choice;
        sessionStorage.setItem('last-login', choice);
    },
};

const actions = {
    login: (context, user) => {
        context.commit('auth_login', user)
    },
    logout: (context) => {
        context.commit('auth_logout');
    },
    setChoice: (context, {choice}) => {
        context.commit('set_choice', choice)
    },
    getChoice: (context, {username}) => {
        ChoicesService.getAll(username).then(response =>{
            context.commit('set_choice', response.data);
            window.location.reload();
        })
    },
    getLastLogin: (context, {username}) => {
        UserService.getLastLogin(username).then(response => {
            context.commit('set_lastlogin', response.data);
        })
    }
};

export const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions
});