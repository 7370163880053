<template>
  <v-app id="keep">
    <Header/>
    <Footer/>
    <EndTimeAlert/>

    <v-main>
      <v-container
          fluid
          class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
              src="../../assets/University-of-Surrey-logo-(Black).png"
              height="280"
              width="395"
              class="center"
          >
          <h1>Interlingual subtitle voicing</h1><br>

          <h5>WHAT IS ISV?</h5>

          ISV relies on automatic speech recognition technology to enable subtitlers to orally dictate (rather
          than type) subtitles. Speech recognition in translation has proven to be 3 to 5 times faster
          than typing, which is an important element of efficiency. However, specialised tools integrating SR have so
          far been available
          only in major languages such as English, German, French, Spanish, Italian, Chinese, Japanese
          etc. Only recently automatic speech recognition services such as Google Voice Typing (GVT)
          have opened up
          to languages of lesser diffusion such as Croatian.<br><br>

          For the purpose of testing this innovative technique, GVT has been integrated with a proprietary and
          specialised subtitling software, SpeakSubz, that should make the subtitle translation process more
          efficient by adding functionalities that GVT itself does not offer, like adding punctuation. During this
          research you will get access to the online
          version
          of the software and to a range of tutorials, materials and other resources so you can learn the skills
          required by ISV and practise this new technique. You will be among the first professionals to use this
          technique and evaluate whether you deem it helpful in your subtitling work.
          By participating in this experiment you will also be able to influence the development of the specialised tool
          through your feedback in post-testing questionnaires and optional interviews after the experiment.<br><br>

          <h5>WHY ISV?</h5>

          With many streaming services available and most of them planning to localise (Disney+, HBOMax, Paramount+
          (2022), AppleTV,
          Hulu, Amazon) or already localising (Netflix) their content, subtitling is becoming a fast growing
          industry with increasing workload and rates. Demand for lesser used languages such as Croatian
          is steadily growing. The ISV technique aims to increase productivity to be able to meet
          such demand more efficiently.<br><br>

          <h5>WHAT WILL YOU LEARN?</h5>
          This ISV website will provide you with all the steps and resources needed to train you in this
          technique.<br><br>

          These are the steps you will need to complete:
          <ul>
            <li> Be sure to note down your code and password since you will not be able to retrieve it because we don’t
              ask for your personal data.
            </li>
            <li> After reading this introduction you will need to read a PARTICIPANT INFORMATION SHEET and sign an
              INFORMED CONSENT FORM digitally.
            </li>
            <li> Filling out an ONLINE PRE-TESTING QUESTIONNAIRE (10-15 minutes).</li>
            <li>
              Going through the PowerPoint and video PRESENTATIONS and TUTORIALS about the technique (1-2 hours).
            </li>
            <li>
              Doing 6 short EXERCISES leading up to two final exercises testing the new technique (ISV) against
              traditional typing,
              some of which need to be recorded. The recording functionality is integrated with SpeakSubz. (Up to 5
              hours).
            </li>
            <li>
              Filling in a POST-TESTING QUESTIONNAIRE and giving us feedback about the technique and the tool (10-15
              min).
            </li>
            <li>
              Applying for optional interviews to provide us with additional feedback on the ISV technique.
            </li>
          </ul>
          <br>
          The whole testing process will be anonymous. You will have <u><strong>30 days after the registration (within a
          two month window)</strong></u> to complete the training and the
          tasks and you can do it at your own pace. You will be able to ask questions through the <a
            href="/#/contact"
        > contact section</a>
          using your ISV code. For more direct communication (e. g. if you encounter any technical difficulties) you can
          also use your
          e-mail which will not be connected to your ISV Code.
          Since we are primarily interested in the workflow and testing the new technique, we will not put
          emphasis on the quality of translation itself. For the purpose of the study, it is of paramount
          importance that you follow the instructions and <u><strong>record only your first attempts</strong></u> in
          various
          exercises so that we can get as authentic data as possible. Redoing the tasks two or three times
          will
          be helpful to further hone your skills; however, please do only save the recording of your first
          attempt.<br><br>

          <h5>HOW WILL I BENEFIT?</h5>

          You will be among the first professionals to try out a technique combining various technologies in
          an attempt to create a more efficient method of subtitle translation. The proposed technique also enables a
          more
          varied translation process, moving away from the desktop environment and taking advantage of the most
          recent developments in speech recognition (SR) and audiovisual translation (AVT).
          Your results and feedback are essential for future development of the technique and the accompanying
          tool. A summary of the results will be published on this website. After you complete the post-testing
          questionnaire you
          can contact us through the <a href="/#/contact"> contact section </a> to participate in
          optional follow-up interviews and take
          part in the testing and future development of the technique and the software.<br><br>

          We would also like to reward your effort with an Amazon voucher in the value of 40 GBP upon the completion of
          the experiment and
          confirmation that all of the necessary tasks were completed.
          Within 30 days of you completing the test, you can expect the voucher to appear once you open the ISV website.
          In case of any problems, feel free to contact us through the ISV contact section.<br><br>

          <v-checkbox
              v-model="buttonStatus"
              :disabled="!checkboxStatus"
              :label="`I have read the introduction`"
              @click="setCheckbox()"
          />

          <v-btn
              :disabled="!buttonStatus"
              to="/informationsheet"
          >
            Go to information sheet
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ChoicesService from "../../services/ChoicesService";
import UserService from "../../services/UserService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import EndTimeAlert from "@/components/EndTimeAlert";

export default {
  components: {EndTimeAlert, Footer, Header},
  props: {
    source: String,
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 1).then(response => {
        this.$store.dispatch('setChoice', {'choice': 2})
      });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 1) {
        this.checkboxStatus = false
        this.buttonStatus = true
      }
    },
  },
    mounted() {
      this.checkStatus();
      UserService.updateLastLogin(store.getters.getUsername);
    }
}

</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>