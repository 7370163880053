<template>
  <v-app id="keep">
    <AdminHeader />
    <AdminFooter />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5 fill-height"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex xs6>
            <div class="list row">
              <div class="col-md-6">
                <h4>Notifications List</h4>
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    :class="{ active: index === currentIndex }"
                    v-for="(notification, index) in notifications"
                    :key="index"
                    @click="setActiveNotification(notification, index)"
                  >
                    {{ notification.notification }}
                  </li>
                </ul>

                <button
                  class="m-3 btn btn-sm btn-danger"
                  @click="removeAllNotification"
                >
                  Remove All
                </button>
              </div>
              <div class="col-md-6">
                <div v-if="currentNotification">
                  <h4>Notification</h4>
                  <div>
                    <label><strong>Date:</strong></label> {{ currentNotification.date }}
                  </div>
                  <div>
                    <label><strong>Notification:</strong></label> {{ currentNotification.notification }}
                  </div>
                  <div>
                    <label><strong>User:</strong></label> {{ currentNotification.username }}
                  </div>
                  <a
                    class="badge badge-warning"
                    :href="'#/admin/editnotification/' + currentNotification.id"
                  >
                    Edit
                  </a>
                </div>
                <div v-else>
                  <br>
                  <p>Please click on a notification...</p>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NotificationsDataService from "../../../services/NotificationsDataService";
import AdminFooter from "@/components/admin/AdminFooter";
import AdminHeader from "@/components/admin/AdminHeader";

export default {
  props: {
    source: String,
  },
  components: {
    AdminHeader, AdminFooter
  },
  data: () => ({
    notifications: [],
    currentNotification: null,
    currentIndex: -1,
    notification: "",
    disabled: false,
  }),
  methods: {
    retrieveNotification() {
      NotificationsDataService.getAll()
          .then(response => {
            this.notifications = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },

            refreshList() {
                this.retrieveNotification();
                this.currentNotification = null;
                this.currentIndex = -1;
            },

            setActiveNotification(notification, index) {
                this.currentNotification = notification;
                this.currentIndex = index;
            },

            removeAllNotification() {
                NotificationsDataService.deleteAll()
                    .then(response => {
                        console.log(response.data);
                        this.refreshList();
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        },
        mounted() {
            this.retrieveNotification();
        }

    }
</script>

<style>
</style>