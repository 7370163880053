<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue darken-3"
    dark
  >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-toolbar-title
      style="width: 300px"
      class="ml-0 pl-4"
    >
      <span class="hidden-sm-and-down">Interlingual Subtitle Voicing</span>
    </v-toolbar-title>
    <v-spacer />
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-badge
          :bordered="bordered"
          :color="color"
          :content="content"
          overlap
          offset-y="19"
          offset-x="19"
        >
          <v-btn
            icon
            to="/notifications"
          >
            <v-icon v-on="on">
              mdi-bell-outline
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
      <span>Notifications</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/faq"
        >
          <v-icon v-on="on">
            mdi-comment-text-outline
          </v-icon>
        </v-btn>
      </template>
      <span>FAQ</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/contact"
        >
          <v-icon v-on="on">
            mdi-comment-question-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Contact</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          icon
          to="/"
        >
          <v-icon v-on="on">
            mdi-logout
          </v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import UserService from "../services/UserService";
import {store} from "@/store";

export default {
  name: "Header",
  data: () => ({
    drawer: null,
    bordered:  false,
    color: 'blue darken-3',
      content: '0',
  }),
  methods: {
    checkLastLogin() {
      if (store.getters.getLastLogin === 'true' || store.getters.getLastLogin === true) {
        this.bordered = true;
        this.color = 'error';
        this.content = '1';
        UserService.updateLastLogin(store.getters.getUsername);
      }
    },
  },
  mounted() {
  this.checkLastLogin()
}
}
</script>
<style scoped>
#keep .v-navigation-drawer__border {
  display: none
}
</style>