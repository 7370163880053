<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div>
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="270"
            width="395"
            class="center"
          >
          <v-layout justify-center>
            <v-flex xs6>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSe7mNiT91NIbzF4-792sld8q1IDERLX-ODD0SqjX9GBllkHXw/viewform?embedded=true"
                width="654"
                height="544"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >Loading…
              </iframe>
            </v-flex>
          </v-layout>
          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have finished the questionnaire`"
            @click="setCheckbox()"
          />

          <v-btn
            to="/followup"
            :disabled="!buttonStatus"
          >
            Go to Optional Follow-up Interview
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 10).then(response => {
        this.$store.dispatch('setChoice', {'choice': 11}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 10) {
              this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
</style>