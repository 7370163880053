<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div>
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="270"
            width="395"
            class="center"
          >
          <v-layout center>
            <h5>
              <div align="center">
                Please click on the SpeakSubz logo to access SpeakSubz.
                After you finish all the exercises, please come back to the ISV website and complete the post-testing questionnaire.
              </div>
            </h5>
            <a
              href="https://speaksubz.com"
              target="_blank"
            ><img src="../../assets/speaksubzlogo.png"></a>
          </v-layout>
          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have finished the exercises`"
            @click="setCheckbox()"
          />

          <v-btn
            to="/posttestingquestionnaire"
            :disabled="!buttonStatus"
          >
            Go to questionnaire
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 9).then(response => {
        this.$store.dispatch('setChoice', {'choice': 10}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 9) {
              this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
</style>