<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-center font-weight-light subtitle-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="270"
            width="395"
            class="center"
          >

          <v-expansion-panels>
            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>Nothing happens when I speak into SpeakSubz.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Make sure your microphone is connected and turned on. You can use various online microphone tests to
                check your microphone.
                You also need to allow SpeakSubz to access your microphone. You should be prompted to do this the first
                time you start SpeakSubz and/or plug in your microphone.
                If you are not prompted, you can click on the padlock left of the search box of your browser (1) and
                then choose “allow” from the microphone dropdown menu (2).<br>
                <img src="../../assets/faq/1_1.jpg">
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>How do I record my screen?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You should be prompted to record your screen when you open a task.<br>
                <img src="../../assets/faq/2_1.jpg"><br>

                You need to click on the screen/window you want to record (1), share the sound (2) and confirm (3).
                If you are not prompted to do this, simply press the RECORD button.<br>
                <img src="../../assets/faq/2_2.jpg"><br>

                When you have completed the recording, press stop and enter the name of the recording (e.g. voicing,
                editing, complete).<br>
                <img src="../../assets/faq/2_3.jpg">
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>I can’t see my bottom action buttons.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <img src="../../assets/faq/3_1.jpg"><br>

                This depends on the device you are using. If this happens, you need to reduce the size of your screen.

                Go to your browser settings (1) and adjust the size (2).<br>
                <img src="../../assets/faq/3_2.jpg"><br>

                Going to full screen mode should also help with this.<br>
                <img src="../../assets/faq/3_3.jpg">
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>My spell checker is not working.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                In Google Chrome, you need to go to Settings (Postavke).<br>
                <img src="../../assets/faq/4_1.jpg"><br>


                After that click on Advanced (Napredno) and Languages (Jezici).<br>
                <img src="../../assets/faq/4_2.jpg"><br>


                Finally, select Croatian and basic spellcheck.<br>
                <img src="../../assets/faq/4_3.jpg"><br>

                In case this does not work, you can always download your subtitle file (see question number 5) in the
                .srt form, open it in notepad, copy/paste the text and spell check the translation elsewhere (e.g.
                Word).
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>How do I save and download my subtitle file?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can always download your subtitle files for your own reference or for the spell checker (see
                question 4).
                If your Autosave is activated, each time you go from one subtitle to the next, your translation is
                saved.<br>
                <img src="../../assets/faq/5_1.jpg"><br>


                When you complete a certain activity (voicing or editing), you need to click on the Save As button and
                enter the name of the file (either voicing or editing, depending on what you did in that subtitle file).<br>
                <img src="../../assets/faq/5_2.jpg"><br>
                <img src="../../assets/faq/5_3.jpg"><br>

                If you want to download it, click on download.<br>
                <img src="../../assets/faq/5_4.jpg"><br>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>How do I watch my subtitles against the video (self-QC)?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                For this, you need to click on the Preview button.<br>
                <img src="../../assets/faq/6_1.jpg"><br>

                When you want to go back to the editing mode, click on Normal.<br>
                <img src="../../assets/faq/6_2.jpg"><br>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>My SpeakSubz has stopped capturing my sound.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                If this happens, or in general, if your internet connection gets interrupted or your screen freezes,
                simply click on Refresh in your browser.
                <img src="../../assets/faq/7_1.jpg"><br>

                Make sure your Autosave setting is turned on so you do not lose your work in cases like this.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>How do I correct speech recognition errors?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                You can enter incorrectly recognized words by using the SpeakSubz Dictionary function.<br>
                <img src="../../assets/faq/8_1.jpg"><br>

                Click on “Add Word” and then enter both the incorrect and correct word.<br>
                <img src="../../assets/faq/8_2.jpg"><br>

                After that click “Save” and next time this word occurs, by clicking twice on the highlighted word, you
                will be able to choose the correct one from the Dictionary.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>How will I receive my Amazon voucher?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Once you complete the experiment process and once the researcher checks whether the necessary exercises
                have been completed and whether the necessary subtitle files and screen and voice recordings (both unedited and edited/final)
                are in the ISV cloud, allow for the maximum of 30 days for the voucher code for the Amazon website of your choice (.com/.uk/.de or other)
                to appear in your notification section of the ISV website.
                You can then apply this code to your Amazon account. In case of any problems,
                please contact the researcher through the <a href="/#/contact"> contact section </a>.
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>What if SpeakSubz records my screen but not the sound?</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                If the system does not record your sound, choose the second option (window), instead of "entire screen".<br>
                <img src="../../assets/faq/9.jpg"><br>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>My SpeakSubz does not turn spoken punctuation into symbols.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                If this happens mid work, you can try saving your work and refreshing your browser.

                If this does not help and if it keeps happening, you can go to Dictionary/Rječnik and Add word/Dodaj riječ.
                Here you can enter the word as wrong word (e,g, točka) and below (correct words) enter the symbol (.). Refresh if needed.<br>
                <v-img src="../../assets/faq/10_1.jpg"></v-img><br>

                If this does not help either, you can always use virtual punctuation keys below the video on the left side.<br>
                  <v-img src="../../assets/faq/10_2.jpg"></v-img><br>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="openedPanel">
              <v-expansion-panel-header>
                <strong>My SpeakSubz does not work in Internet Explorer/Firefox and/or other internet browsers.</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Please try using <a href="https://www.google.com/chrome/" target="_blank">Google Chrome.</a>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    disabled: false,
    questionsAnswers: [],
    question: "",
    answer: "",
    openedPanel: null
  }),
  methods: {
    closeAllPanels() {
      this.openedPanel = null
    },
    openPanel(index) {
      this.openedPanel = index
    },
  },
}
</script>

<style>
</style>