import http from "../components/http-common.js";

class MailService {
    sendMail(from, subject, text) {
        let formData = new FormData();

        formData.append("email", from);
        formData.append("subject", subject);
        formData.append("message", text);

        return http.post("/sendHtmlEmail", formData);
    }
}

export default new MailService();
