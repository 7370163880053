import axios from 'axios'

export default axios.create({
    baseURL: `/api`,
    // baseURL: `http://localhost:8080/api`,
    headers: {
        'Access-Control-Allow-Origin': ['http://isvresearch.eu', 'http://isvresearch.eu', 'http://isvresearch.eu.8080', 'http://isvresearch.eu:8081', 'https://isvresearch.eu.8080', 'https://isvresearch.eu:8081', 'https://185.203.18.175:8081', 'https://185.203.18.175:8081', 'http://localhost:8080', 'http://localhost:8081', 'http://localhost:8080/api`'],
        'Access-Control-Allow-Methods': 'GET,POST,DELETE,PUT,OPTIONS',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': true
    }
})