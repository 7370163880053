<template>
  <v-app id="keep">
    <AdminHeader/>
    <AdminFooter/>

    <v-main>
      <v-container
          fluid
          class="grey lighten-5 fill-height"
      >
        <v-layout
            align-center
            justify-center
        >
          <v-flex xs6>
            <div
                class="submit-form"
                align="center"
            >
              <div v-if="!submitted">
                <div
                    class="form-group"
                    align="center"
                >
                  <label for="answer">Notification text</label>
                  <input
                      class="form-control"
                      id="answer"
                      required
                      v-model="notifications.notification"
                      name="answer"
                  >
                  <br>
                  Is it for selected user?
                  <input type="checkbox" v-model="selection"><br><br>

                  <div :hidden="!selection">
                    Please select user:
                    <select class="border-4 red" v-model="selectedUser">
                      <option v-for="user in users"
                              :value="user"
                              v-bind:value="user.username">{{ user.username }}
                      </option>
                    </select><br><br>
                  </div>

                </div>

                <button
                    @click="saveNotification"
                    class="btn btn-success"
                >
                  Submit
                </button>
              </div>

              <div v-else>
                <h4>You submitted successfully!</h4>
                <button
                    class="btn btn-success"
                    @click="newNotification"
                >
                  Add
                </button>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NotificationsDataService from "../../../services/NotificationsDataService";
import AdminHeader from "@/components/admin/AdminHeader";
import AdminFooter from "@/components/admin/AdminFooter";
import ChoicesService from "@/services/ChoicesService";

export default {
  props: {
    source: String,
  },
  components: {
    AdminHeader, AdminFooter
  },
  data: () => ({
    notifications: {
      id: null,
      date: "",
      notification: "",
    },
    users: [],
    submitted: false,
    selection: false,
    selectedUser: null
  }),
  methods: {
    saveNotification() {
      let data;
      if (this.selection === false) {
        data = {
          notification: this.notifications.notification,
          username: null
        };
      } else {
        data = {
          notification: this.notifications.notification,
          username: this.selectedUser
        };
      }

      console.log(data);

      NotificationsDataService.create(data)
          .then(response => {
            this.notifications.id = response.data.id;
            this.submitted = true;
          })
          .catch(e => {
            console.log(e);
          });
    },
    getUsers() {
      ChoicesService.getAllUsers()
          .then(response => {
            this.users = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
    newNotification() {
      this.submitted = false;
      this.notifications = {};
    },
  },
  mounted() {
    this.getUsers();
  }
}
</script>

<style>
</style>