import axios from 'axios'

class SpeakSubzService {


    registerNewUser(username, password) {
        let formData = new FormData();

        formData.append("access_token", 'e1a16b0dd70524258b3eebe6c0e311ea2281a10f6d4d56d16cccbb2c22cbbd5cb27220707b2f7e573635889dd61fdfd3d131a30624258b3eef83e4f8479a2281a10f6d4d56d16cccbb2c22cbbd5cb27');
        formData.append("username", username);
        formData.append("password", password);

        return axios.post('https://speaksubz.com:4000/ext/register_user', formData, {
            headers: {
                'Access-Control-Allow-Origin': ['https://speaksubz.com:4000/ext/register_user', 'http://isvresearch.eu', 'http://localhost']
            }
        });
    }
}

export default new SpeakSubzService();