<template>
  <div class="wrapper">
    <header class="page-header">
      <nav>
        <img src="../assets/University-of-Surrey-logo-(Blue).png" class="surrey-picture">
        <h3><strong>ISV - INTERLINGUAL SUBTITLE VOICING</strong> <img src="../assets/mic.png" class="isv-picture"></h3>
        <img src="../assets/surreycentre.png" class="centre-picture">
      </nav>
    </header>

    <main class="page-main">
      <h4 class="pb-2">ISV – A new technique for creating non-live subtitles through direct interaction with
        speech recognition.<br>Aims: increasing efficiency and enhancing the subtitler experience.
      </h4>
      <v-row class="flex-nowrap">
        <v-card class="cards">
          <v-card-title><h3 class="mx-auto">What?</h3></v-card-title>
          <v-card-text>
            <ul>
              <li><h6><strong>SRQ1:</strong> How does the technique influence <strong>efficiency</strong> intended as
                speed (voicing versus typing) and accuracy (speech recognition)?</h6></li>
              <li><h6><strong>SRQ2:</strong> In what way does the ISV influence the traditional subtitling workflow and the <strong>subtitler experience</strong> (SUBX)?
              </h6></li>
            </ul>
            <br>
            <img src="../assets/p1.png" class="card-picture-smaller">
            <img src="../assets/p2.png" class="card-picture-smaller">
            <img src="../assets/p3.png" class="card-picture-smaller">

          </v-card-text>
        </v-card>

        <v-card class="cards">
          <v-card-title><h3 class="mx-auto">Why?</h3></v-card-title>
          <v-card-text>
            <ul>
              <li>
                <h6>
                  Need to improve efficiency and motivation by introducing variety and handheld devices into the workflow.
                </h6>
              </li>
              <li>
                <h6>
                  Need to improve mobility and positively influence physical and mental wellbeing.
                </h6>
              </li>
            </ul>

            <img src="../assets/surrey_lik.png" class="card-picture-bigger">

          </v-card-text>

        </v-card>

        <v-card class="cards">
          <v-card-title><h3 class="mx-auto">How?</h3></v-card-title>
          <v-card-text>
            <ul>
              <li>
                <h6>
                  Under 10 hours of specialised ISV training (video tutorials, 8 exercises)
                </h6>
              </li>
              <li>
                <h6>
                  Pre- and post-training questionnaires
                </h6>
              </li>
              <li>
                <h6>
                  Specialised voicing software integrated within the platform SpeakSubz
                </h6>
              </li>
              <li>
                <h6>
                  Delivery of experiment via methodological online hub
                </h6>
              </li>
            </ul>
            <img src="../assets/app.png" class="card-picture-bigger pl-3">

          </v-card-text>
        </v-card>
      </v-row>

      <h4>
        <br>
        <strong>The current ISV research cycle is closed. Please send an e-mail to the head researcher at <a href="mailto:z.radic@surrey.ac.uk">z.radic@surrey.ac.uk</a> to express
          interest <br>in participating in future research and development cycles or for more information.
          <br>The next ISV research cycle will include machine translation and utilisation on handheld devices. (Updated: June 2024)</strong>
      </h4>

      <br>
      <v-btn
          to="/login"
          v-if="!this.$store.getters.isAuthenticated">
        Login
      </v-btn>

      <v-btn v-if="this.$store.getters.isAuthenticated">
        Return to speaksubz
      </v-btn>

      <v-btn to="/admin/getUsers"
             v-if="this.$store.getters.isAuthenticated && this.$store.getters.isAdmin">
        Admin
      </v-btn>
      <br>
    </main>

    <footer class="page-footer">
              <h6> PhD Researcher: Željko Radić (<a href="mailto:z.radic@surrey.ac.uk">z.radic@surrey.ac.uk</a>)<br>
      Supervisory Team: Dr Elena Davitti & Prof Sabine Braun</h6>
    </footer>

  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper > * {
  padding: 5px;
}

.page-main {
  flex-grow: 1;
}

.page-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

:root {
}

.page-main {
  background-color: #eafafa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 350px;
  background-blend-mode: luminosity;
}

.page-main div {
  max-width: 2000px;
  max-height: 500px;
}

.page-footer {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  background-color: #e5e5e5;
}

.cards {
  color: #eafafa;
  width: 32% !important;
  height: 405px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.surrey-picture {
  width: 9%;
  height: 9%;
}

.isv-picture {
  width: 9%;
  height: 9%;
}

.centre-picture {
  width: 6%;
  height: 6%;
}

.card-picture-bigger {
  padding-left: 10%;
  width: 70%;
  height: 70%;
}

.card-picture-smaller {
  padding-left: 5%;
  width: 20%;
  height: 20%;
}

@media (max-width: 1100px) {
  .card-picture-bigger {
    display: none;
  }

  .card-picture-smaller {
    display: none;
  }
}
</style>