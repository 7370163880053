<template>
  <v-app id="keep">
    <AdminHeader />
    <AdminFooter />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5 fill-height"
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex xs6>
            <div class="text-xs-center">
              <v-simple-table class="grey lighten-5">
                <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      User
                    </th>
                    <th class="text-left">
                      Consent accepted on:
                    </th>
                    <th class="text-left">
                      Registered on:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in users"
                    :key="user"
                  >
                    <td>{{ user.username }}</td>
                    <td>{{ user.choices[0].secondDate}}</td>
                    <td>{{ user.registered}}</td>
                  </tr>
                </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ChoicesService from "../../services/ChoicesService";
import AdminHeader from "@/components/admin/AdminHeader";
import AdminFooter from "@/components/admin/AdminFooter";

export default {
  props: {
    source: String,
  },
  components: {
    AdminHeader, AdminFooter
  },

  data: () => ({
    disabled: false,
    users: []
  }),
  methods: {
    getUsers() {
      ChoicesService.getAllUsers()
          .then(response => {
            this.users = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.getUsers()
  }
}

</script>

<style>
</style>