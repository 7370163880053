import http from "../components/http-common.js";

class DataService {
    getAll(username) {
        let formData = new FormData();

        formData.append("username", username);

        return http.post("/choices/getChoices", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });

    }

    getAllForUser(username) {
        let formData = new FormData();

        formData.append("username", username);

        return http.post("/choices/getChoicesForUser", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });

    }

    getAllUsers() {
        return http.get("/choices/getUsers");
    }

    setChoice(username, choice) {
        let formData = new FormData();

        formData.append("username", username);
        formData.append("choice", choice);

        return http.post("/choices/assign", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
    }
}

export default new DataService();
