<template>
  <div id="app">

    <router-view />
  </div>
</template>

<script>
import AXIOS from './components/http-common'

export default {
  name: 'App',
  methods: {
    confirmRegistration() {
      if (this.$route.query.confirmRegistration === 'true' && this.$route.query.token != null) {
        this.$router.push({name: 'RegistrationConfirmPage', params: {token: this.$route.query.token}});
      }
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');

      AXIOS.post(`/auth/logout`)
          .then(response => {
            console.log(response.statusText);
            this.$store.dispatch('logout');
            this.$router.push('/');
          }, error => {
            console.log(error)
            alert("Server error. Please, try later")
          })
          .catch(e => {
            console.log(e);
          })
    }
  },
  mounted() {
    this.confirmRegistration();
  }
}
</script>

<style>
</style>
