<template>
  <v-app id="keep">
    <AdminHeader/>
    <AdminFooter/>

    <v-main>
      <v-container
          fluid
          class="grey lighten-5 fill-height"
      >
        <v-layout
            align-center
            justify-center
        >
          <v-flex xs6>
            <div
                v-if="currentNotification"
                class="edit-form"
            >
              <h4>Notification</h4>
              <form>
                <div class="form-group">
                  <label for="description">Text</label>
                  <input
                      type="text"
                      class="form-control"
                      id="description"
                      v-model="currentNotification.notification"
                  >
                </div>
              </form>

              <button
                  class="badge badge-danger mr-2 red"
                  @click="deleteNotification"
              >
                Delete
              </button>

              <button
                  type="submit"
                  class="badge badge-success blue"
                  @click="updateNotification"
              >
                Update
              </button>
              <p>{{ message }}</p>
            </div>

            <div v-else>
              <br>
              <p>Please click on a Notification...</p>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import NotificationsDataService from "../../../services/NotificationsDataService";
import AdminHeader from "@/components/admin/AdminHeader";
import AdminFooter from "@/components/admin/AdminFooter";

export default {
  props: {
    source: String,
  },
  components: {
    AdminHeader, AdminFooter
  },
  data: () => ({
    currentNotification: null,
    message: '',
    disabled: false,
  }),
  methods: {
    getNotification(id) {
      NotificationsDataService.get(id)
          .then(response => {
            this.currentNotification = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },

    updateNotification() {
      NotificationsDataService.update(this.currentNotification.id, this.currentNotification)
          .then(response => {
            console.log(response.data);
            this.message = 'The notification was updated successfully!';
          })
          .catch(e => {
            console.log(e);
          });
    },

    deleteNotification() {
      NotificationsDataService.delete(this.currentNotification.id)
          .then(response => {
              console.log(response.data);
            this.$router.push("/admin/listnotifications");
          })
          .catch(e => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.message = '';
    this.getNotification(this.$route.params.id);
  }

}
</script>

<style>
</style>