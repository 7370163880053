import http from "../components/http-common.js";

class DataService {
    getAll() {
        return http.get("/notifications");
    }

    get(id) {
        return http.get(`/notifications/${id}`);
    }

    getForUser(user) {
        return http.get(`/notifications/${user}/all`);
    }

    create(data) {
        return http.post("/notifications", data);
    }

    update(id, data) {
        return http.put(`/notifications/${id}`, data);
    }

    delete(id) {
        return http.delete(`/notifications/${id}`);
    }

    deleteAll() {
        return http.delete(`/notifications`);
    }

}

export default new DataService();
