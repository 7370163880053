import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import SignIn from '@/components/SignIn'
import SignUp from '@/components/SignUp'
import RegistrationConfirmPage from '@/components/RegistrationConfirmPage'
import PostTestingQuestionnaire from "@/components/steps/PostTestingQuestionnaire";
import PretestingQuestionnaire from "@/components/steps/PreTestingQuestionnaire";
import Results from "@/components/steps/Results";
import ISVTutorials from "@/components/steps/ISVTutorials";
import Contact from "@/components/tabs/Contact";
import Faq from "@/components/tabs/Faq";
import Notifications from "@/components/tabs/Notifications";
import Introduction from "./components/steps/Introduction";
import Notes from "./components/steps/Notes";
import {store} from "./store/index";
import AddNotifications from "./components/admin/notifications/AddNotifications";
import EditNotifications from "./components/admin/notifications/EditNotifications";
import NotificationsList from "./components/admin/notifications/NotificationsList";
import InformationSheet from "./components/steps/InformationSheet";
import ConsentForm from "./components/steps/ConsentForm";
import GetUsers from "./components/admin/GetUsers";
import Exercises from "@/components/steps/Exercises";
import ISVPresentation from "@/components/steps/ISVPresentation";
import SpeakSubzPage from "@/components/steps/SpeakSubzPage";
import FollowUpInterviews from "@/components/steps/FollowUpInterviews";


Vue.use(Router)
let isAuthenticated;

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/login',
            name: 'SignIn',
            component: SignIn,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/registernewuser',
            name: 'SignUp',
            component: SignUp
        },
        {
            path: '/introduction',
            name: 'Introduction',
            component: Introduction,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()

            }
        },
        {
            path: '/registration-confirm/:token',
            name: 'RegistrationConfirmPage',
            component: RegistrationConfirmPage,
        },
        {
            path: '/notes',
            name: 'Notes',
            component: Notes,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/posttestingquestionnaire',
            name: 'PostTestingQuestionnaire',
            component: PostTestingQuestionnaire,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/pretestingquestionnaire',
            name: 'PretestingQuestionnaire',
            component: PretestingQuestionnaire,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/informationsheet',
            name: 'InformationSheet',
            component: InformationSheet,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/consentform',
            name: 'ConsentForm',
            component: ConsentForm,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/exercises',
            name: 'Exercises',
            component: Exercises,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()

            }
        },
        {
            path: '/presentation',
            name: 'ISV Presentation',
            component: ISVPresentation,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/speaksubz',
            name: 'SpeakSubz',
            component: SpeakSubzPage,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/followup',
            name: 'Followup Interviews',
            component: FollowUpInterviews,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/isvtutorials',
            name: 'ISV Tutorials',
            component: ISVTutorials,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/results',
            name: 'Results',
            component: Results,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/notifications',
            name: 'Notifications',
            component: Notifications,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/faq',
            name: 'Faq',
            component: Faq,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAuthenticated
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/admin/addnotification',
            name: 'AddNotification',
            component: AddNotifications,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAdmin
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/admin/editnotification/:id',
            name: 'EditNotification',
            component: EditNotifications,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAdmin
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/admin/listnotifications',
            name: 'ListNotifications',
            component: NotificationsList,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAdmin
                if (!isAuthenticated) next("/")
                else next()
            }
        },
        {
            path: '/admin/getusers',
            name: 'GetUsers',
            component: GetUsers,
            beforeEnter: (to, from, next) => {
                isAuthenticated = store.getters.isAdmin
                if (!isAuthenticated) next("/")
                else next()
            }
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
})

let isFirstTransition = true;

router.beforeEach((to, from, next) => {
    const lastRouteName = sessionStorage.getItem("LS_ROUTE_KEY");

    const shouldRedirect = Boolean(
        to.name === "Home"
        && lastRouteName
        && isFirstTransition
    );

    if (shouldRedirect) next({name: lastRouteName});
    else next();

    isFirstTransition = false;

    router.afterEach(to => {
        sessionStorage.setItem("LS_ROUTE_KEY", to.name);
    });

});


export default router;