<template>
  <v-snackbar
    :timeout="4000"
    v-model="snackbar"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
import UserService from "../services/UserService";
import {store} from "@/store";

export default {
  name: "EndTimeAlert",
  data: () => ({
    text: null,
    snackbar: false
  }),
  methods: {
    checkEndTime() {
      UserService.getEndTime(store.getters.getUsername).then(response => {
        if (response.status === 200) {
          this.text = response.data
          if(sessionStorage.getItem('time-displayed') !== 'true'){
            sessionStorage.setItem('time-displayed', 'true');
            this.snackbar = true;
          }
        }
      })
          .catch(e => {
            console.log(e);
          });
    }
  },
  mounted() {
    this.checkEndTime()
  }
}

</script>
