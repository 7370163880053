<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >

          <h5>Before you start:</h5>
          <ol>
            <li>
              Make sure you have a microphone, earphones with a microphone or a headset. If you have more, check which
              works best for you in Exercise 0.
            </li>
            <li>Watch the video presentation about ISV in the next tab </li>
            <li>Watch short tutorials on how to use SpeakSubz (you can always go back and re-watch)</li>
            <li>Go to the SpeakSubz website</li>
            <li>
              SpeakSubz will ask you for access to your microphone. Please allow for successful participation.<br>
              <img src="../../assets/notes/h1.jpg" ></img>
            </li>
            <li>
              Start with Exercise 0 where you can try out functionalities of SpeakSubz and whether your hardware is
              working. Here you can also try out touch screen input if you'd like to try it on handheld devices like
              tablets or if you have hybrid laptops with touch screens.
            </li>
            <li>
              Before you open each exercise, be sure to open the READ ME button to get instructions about what to do
              in each exercise.<br>
              <img src="../../assets/notes/h2.jpg" ></img>
            </li>
            <li>
              Record your screen while doing exercises 2 and 6 (for practise) and final ISV exercises 7 and 8 (for
              evaluation). You can record your screen by clicking on the screen and then confirming. <br>
              <v-img src="../../assets/notes/h3.jpg"></v-img>
            </li>
            <li>
              If the system does not record your sound, choose the second option (window/prozor), instead of "entire screen/cijeli zaslon".
              You can check this under "Recordings" button in each task.
              Feel free to try this in Exercise 0, 2 or 6 where you can practise recording.  <br>
              <v-img src="../../assets/faq/9.jpg"></v-img>
            </li>
          </ol>

          <br>

          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I agree to terms of content`"
            @click="setCheckbox()"
          />
          <v-btn
            :disabled="!buttonStatus"
            to="/presentation"
          >
            Go to presentations
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 5).then(response => {
        this.$store.dispatch('setChoice', {'choice': 6}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 5) {
        this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
</style>