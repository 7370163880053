<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    color="grey lighten-5"
  >
    <v-list
      dense
      class="grey lighten-5"
    >
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading"
          :key="i"
          align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
            cols="6"
            class="text-right"
          >
            <v-btn
              small
              text
            >
              edit
            </v-btn>
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        />
        <v-list-item
          v-else
          :key="i"
          :to="item.link"
          link
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AdminFooter",
  drawer: null,
  choices: null,
  data: () => ({
    items: [
      {icon: 'mdi-numeric-1-box-outline', text: 'Add notifications', link: '/admin/addnotification'},
      {icon: 'mdi-numeric-2-box-outline', text: 'Notification list', link: '/admin/listnotifications'},
      {divider: true},
      {icon: 'mdi-numeric-3-box-outline', text: 'Get users', link: '/admin/getusers'},
      {divider: true},
    ],
  }),
}
</script>

<style scoped>

</style>