<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >

          In this section you can watch two video presentations. The first one (General Presentation) is a presentation about the
          ISV technique and the second one (ISV Exercises) elaborates on ISV training and exercises in more detail.
          Feel free to watch videos and download the PowerPoint presentations for your own reference.
          Should you have any questions, feel free to contact the researcher through the ISV contact section or the university e-mail z.radic@surrey.ac.uk.<br><br>

          <p>General Presentation</p>
          <video
            id="video1"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/General_Presentation.mp4"
              type="video/mp4"
            >
          </video>

          <p>ISV Exercises</p>
          <video
            id="video2"
            controls
            preload="metadata"
            crossorigin="anonymous"
          >
            <source
              src="../../assets/Tutorials/Isv_Exercises.mp4"
              type="video/mp4"
            >
          </video><br>

          Download links:
          <ul>
            <li>
              <a
                href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21121&authkey=AJ57th6piIrBkAs"
                download
              >General Presentation</a>
            </li>
            <li>
              <a
                href="https://onedrive.live.com/download?cid=C0539E8A0D0FD0C5&resid=C0539E8A0D0FD0C5%21123&authkey=APsNSVgJaBTMZm4"
                download
              >ISV Exercises</a>
            </li>
          </ul>

          If after this you have any questions, feel free to <a href="/#/contact">contact us</a> on the
          website and questions will be
          answered regularly on a daily basis and based on them <a href="/#/faq"> a FAQ</a> will be created.
          <br>
          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have gone through the PowerPoint presentations.`"
            @click="setCheckbox()"
          />
          <v-btn
            to="/exercises"
            :disabled="!buttonStatus"
          >
            Go to exercises
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 6).then(response => {
        this.$store.dispatch('setChoice', {'choice': 7}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 6) {
              this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>