<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <img
          src="../../assets/University-of-Surrey-logo-(Black).png"
          height="280"
          width="395"
          class="center"
        >


        <v-row
          align="center"
          justify="center"
        >
          <v-card
            class="mx-auto"
            style="min-width: 500px;"
          >
            <v-system-bar
              color="blue darken-3"
              dark
            />
            <v-toolbar
              color="blue darken-3"
              cards
              dark
              flat
            >
              <v-card-title class="title font-weight-regular">
                Send e-mail
              </v-card-title>
            </v-toolbar>
            <v-form
              ref="form"
              class="pa-4 pt-6"
            >
              <v-text-field
                v-model="subject"
                filled
                label="Subject"
              />

              <v-textarea
                v-model="message"
                auto-grow
                filled
                color="deep-purple"
                label="Message"
                rows="8"
              />

              <v-checkbox
                v-model="checkboxStatus"
                :label="`Optional: Use your personal email for faster communication (Your e-mail will not be paired with your ISV code)`"
              />
              <v-text-field
                v-model="email"
                filled
                label="Your email"
                :disabled="!checkboxStatus"
              />
            </v-form>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                @click="sendEmail"
                class="white--text"
                color="primary"
                depressed
              >
                Submit
              </v-btn>
              <v-snackbar
                v-model="snackbar"
              >
                Message was sent. Thank you!
                <v-btn
                  color="pink"
                  text
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </v-snackbar>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Axios from "../http-common"
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    email: '',
    subject: '',
    message: '',
    snackbar: false,
    text: 'Message was sent',
    disabled: false,
    checkboxStatus: false,
  }),
  methods: {
    sendEmail: function () {
      this.$data.snackbar = true;
      const bodyData = new FormData();
      if (this.$data.email.length < 8) {
        bodyData.set("email", "no-reply@isvresearch.eu")
        bodyData.set("subject", "ISVResearch: User " + store.getters.getUsername + " asked: " + this.$data.subject)
      } else {
        bodyData.set("email", this.$data.email)
        bodyData.set("subject", "ISVResearch: " + this.$data.subject)
      }

      bodyData.set("message", this.$data.message)
      Axios.post("/sendSimpleEmail", bodyData).then(response => {
        if (response !== false) {
          console.log("message sent");
        }
      }, error => {
        console.log(error)
      })
          .catch(error => {
            console.log(error);
          });

    },
  },
}

</script>

<style>
</style>