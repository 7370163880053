<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div class="text-justify font-weight-light body-1">
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >

          All of the instructions are in exercises in SpeakSubz <img src="../../assets/notes/h2.jpg">
          , but here is an overview in one place:
          <ol>
            <li> Use for practise and trying out functionalities of SpeakSubz and hardware, touch-screen, tablets etc.</li>
            <li> Exercise 1: Translate to Croatian by typing</li>
            <li> Exercise 2: Repeat in Croatian by voicing (no punctuation)</li>
            <li> Exercise 3: Repeat in Croatian by voicing (with punctuation) - RECORD FOR PRACTICE</li>
            <li> Exercise 4: Translate to Croatian by voicing (no video)</li>
            <li> Exercise 5: Translate to Croatian by voicing (practise)</li>
            <li> Exercise 6: Translate to Croatian by voicing (practise) - RECORD</li>
            <li> Exercise 7: Translate to Croatian by typing/voicing (main) - RECORD</li>
            <li> Exercise 8: Translate to Croatian by typing/voicing (main) - RECORD</li>
          </ol>
          You will have one month (30 days) to complete exercises, so you can do the whole experiment process depending on your availability, but try not to leave everything for the last few days since it may be overwhelming.

          Your participation is appreciated and you will have a chance to actively shape both the ISV technique and the SpeakSubz tool to create a subtitle translation process that you might be using professionally in the future. So use this opportunity to create a tool you would like to use and that you might find most helpful in your daily work.

          Now please watch the tutorials in the next section and after that you can go to the SpeakSubz website and complete exercises at your own pace,
          but in order: from 1 to 8. Feel free to try different functionalities, hardware and devices to see which suit you the best before starting with the first exercise in exercise 0. Also, record your first attempts since this is crucial for measurements. Thank you.

          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have read this section`"
            @click="setCheckbox()"
          />

          <v-btn
            :disabled="!buttonStatus"
            to="/isvtutorials"
          >
            Go to ISV Tutorials
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {store} from "../../store";
import ChoicesService from "../../services/ChoicesService";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
    username: store.getters.getUsername,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 7).then(response => {
        this.$store.dispatch('setChoice', {'choice': 8}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 7) {
        this.checkboxStatus = false
              this.buttonStatus = true
            }
         }
  },
  mounted() {
    this.checkStatus()

  }
}
</script>

<style>
</style>