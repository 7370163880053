<template>
  <v-app id="keep">
    <Header />
    <Footer />

    <v-main>
      <v-container
        fluid
        class="grey lighten-5"
      >
        <div>
          <img
            src="../../assets/University-of-Surrey-logo-(Black).png"
            height="280"
            width="395"
            class="center"
          >
          Thank you for agreeing to take part in a PhD research project exploring Interlingual Subtitle
          Voicing (ISV), i.e. a new subtitling technique based on speech recognition. <br>Before you start,
          please
          fill out this pre-testing questionnaire.<br> Please scroll down the questionnaire using the bar on the
          right. <br><br>
          <v-layout justify-center>
            <v-flex xs6>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLScN5avxQa5LnCcnIoi96-aiaEMSqa6QGHVA5Ei23wxeuRLDmg/viewform?embedded=true"
                width="654"
                height="544"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >Loading…
              </iframe>
            </v-flex>
          </v-layout>
          <v-checkbox
            v-model="buttonStatus"
            :disabled="!checkboxStatus"
            :label="`I have finished the questionnaire`"
            @click="setCheckbox()"
          />
          <v-btn
            to="/notes"
            :disabled="!buttonStatus"
          >
            Go to notes
          </v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import ChoicesService from "../../services/ChoicesService";
import {store} from "../../store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  props: {
    source: String,
  },
  components: {
    Header, Footer
  },
  data: () => ({
    buttonStatus: false,
    checkboxStatus: true,
  }),
  methods: {
    setCheckbox() {
      ChoicesService.setChoice(store.getters.getUsername, 4).then(response => {
        this.$store.dispatch('setChoice', {'choice': 5}) });
    },
    checkStatus() {
      if (parseInt(store.getters.getChoices) > 4) {
        this.checkboxStatus = false
              this.buttonStatus = true
            }
    }
  },
  mounted() {
    this.checkStatus()
  }
}
</script>

<style>
.center {
  width: 50%;
  height: 100%;
}
</style>