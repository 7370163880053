<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    color="grey lighten-5"
    :width="260"
  >
    <v-list
      dense
      class="grey lighten-5"
    >
      <template v-for="(n, i) in choices">
        <v-row
          v-if="items[i].heading"
          :key="i"
          align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="items[i].heading">
              {{ items[i].heading }}
            </v-subheader>
          </v-col>
          <v-col
            cols="6"
            class="text-right"
          >
            <v-btn
              small
              text
            >
              edit
            </v-btn>
          </v-col>
        </v-row>
        <v-divider
          v-else-if="items[i].divider"
          :key="i"
          dark
          class="my-4"
        />
        <v-list-item
          v-else
          :key="i"
          :to="items[i].link"
          link
        >
          <v-list-item-action>
            <v-icon>{{ items[i].icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ items[i].text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {store} from "@/store";

export default {
  name: "Footer",
  data: () => ({
    drawer: null,
    choices: null,
    items: [
      {icon: 'mdi-check', text: 'Introduction', link: '/introduction'},
      {icon: 'mdi-check', text: 'Participant Information Sheet', link: '/informationsheet'},
      {icon: 'mdi-check', text: 'Informed Consent Form', link: '/consentform'},
      {
        icon: 'mdi-check',
        text: 'Pre-testing questionnaire',
        link: '/pretestingquestionnaire'
      },
      {icon: 'mdi-check', text: 'Before You Start', link: '/notes'},
      {icon: 'mdi-check', text: 'ISV Presentation', link: '/presentation'},
      {icon: 'mdi-check', text: 'Exercises', link: '/exercises'},
      {icon: 'mdi-check', text: 'ISV and SpeakSubz Tutorials', link: '/isvtutorials'},
      {icon: 'mdi-check', text: 'SpeakSubz', link: '/speaksubz'},
      {
        icon: 'mdi-check',
        text: 'Post-testing questionnaire',
        link: 'posttestingquestionnaire'
      },
      {icon: 'mdi-check', text: 'Optional Follow-up Interviews', link: '/followup'},
      {icon: 'mdi-check', text: 'Results', link: '/results'},
      {}
    ],
  }),
  methods: {
    getChoices() {
        this.choices = parseInt(store.getters.getChoices);
    }
  },
  mounted() {
    this.getChoices()
  }
}
</script>

<style scoped>

</style>