import http from "../components/http-common.js";

class UserService {
    getLastLogin(username) {
        return http.get(`/auth/${username}/lastlogin`);
    }

    updateLastLogin(username) {
        return http.post(`/auth/${username}/lastlogin`);
    }

    getEndTime(username) {
        return http.get(`/auth/${username}/endtime`);
    }
}

export default new UserService();
